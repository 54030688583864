<template>
    <ToolbarNode
        id="targetElement"
        class="toolbar-node"
        :note="note"
        :isNoteVisible="isNoteVisible"
        :status="toolbarStatus"
        @mouseenter="handleMouseEnterToolbarNode"
        @mouseleave="handleMouseLeave"
        @update:note="updateNote"
        @update:status="toolbarStatus = $event"
        @update:noteVisible="isNoteVisible = $event"
    ></ToolbarNode>
    <div
        class="p-2 bg-gray-100 w-[400px] !rounded-2xl border border-gray-200 group hover:outline hover:outline-2 hover:outline-gray-400 hover:shadow-lg nodrag nopan"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="$emit('clickOnNode')"
    >
        <div class="flex justify-between items-center">
            <div class="flex gap-x-1 text-sm leading-5 font-medium mb-2 p-1">
                <LightningBoltIcon class="h-4 w-4" />
                <div class="bg-gray-200 rounded-md px-1">{{ nodeTitle }}</div>
                <div class="">enrolment trigger</div>
            </div>
            <TrashIcon
                v-if="showDeleteButton"
                class="hidden group-hover:block w-5 h-5 mb-2 cursor-pointer"
                @click.prevent.stop="$emit('removeNode')"
            ></TrashIcon>
        </div>
        <div
            class="w-full h-full bg-white rounded-2xl flex flex-col text-sm leading-5 font-normal items-center justify-center"
        >
            <div
                v-if="type === TriggerNodeType.contactActivity"
                class="py-6 w-full flex flex-col justify-center items-center p-6"
            >
                <p>The event trigger to enter the workflow:</p>
                <div
                    v-if="event === ContactActivityTriggerNodeType.propertyValueChanged"
                    class="w-full mt-4 mx-4 bg-gray-100 shadow-sm p-1 rounded-xl"
                >
                    <div class="rounded-lg shadow py-2.5 w-full bg-white">
                        <div class="flex gap-x-2 justify-center">
                            <p
                                class="text-sm leading-5 font-medium text-primary-color-700 bg-primary-color-100 w-fit px-1 py-0.5 rounded"
                            >
                                Property Value Changed
                            </p>
                            <p>:</p>
                        </div>
                        <div class="flex justify-center text-sm text-center leading-5 font-semibold text-gray-700 pt-2">
                            {{ selectedData ? selectedData.map((data) => data.label)?.join(", ") : "" }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="bg-primary-color-50 px-2 py-1 text-primary-color-800 font-semibold rounded-md mt-2">
                        {{ convertTriggerEvent(event ? event : "") }}
                    </p>
                </div>
            </div>
            <div v-else-if="type === TriggerNodeType.schedule">
                <div v-if="event === ScheduleTriggerNodeType.timeOfDay">
                    <div class="flex flex-col justify-center items-center p-4 text-gray-900 gap-y-3">
                        <p>Trigger workflow <span class="font-bold">everyday</span></p>
                        <p>
                            at <span class="font-bold">{{ firstValue }}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="flex flex-col justify-center items-center p-4 text-gray-900 gap-y-3">
                    <p>
                        Trigger workflow on
                        <span class="font-bold">{{ firstValue }}</span>
                    </p>
                    <p>
                        at <span class="font-bold">{{ secondValue }}</span>
                    </p>
                </div>
            </div>
            <div v-else-if="enrolledFrom" class="py-6 w-full flex flex-col justify-center items-center p-6">
                <p>Enrolled from workflows:</p>
                <EnrollFromDetails :enrolledFrom="enrolledFrom" />
            </div>
            <div v-else>
                <div class="py-6 w-full flex flex-col justify-center items-center">
                    <div class="items-center text-center w-[281px] text-gray-500 mb-5">
                        Choose the triggers that decide how a person enters this workflow.
                    </div>
                    <GgmsButton
                        class="w-full px-6"
                        style-type="primary"
                        type="button"
                        @click.prevent.stop="changeAction"
                        >Set up trigger/s</GgmsButton
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { LightningBoltIcon } from "@heroicons/vue/solid"
import { TrashIcon } from "@heroicons/vue/outline"
import GgmsButton from "@/components/GgmsButton.vue"
import EnrolmentTriggersSidebar from "@/components/workflows/sidebar/trigger/EnrolmentTriggersSidebar.vue"
import ToolbarNode from "@/components/workflows/ToolbarNode.vue"
import EnrollFromDetails from "@/components/workflows/EnrollFromDetails.vue"
import { convertTriggerEvent } from "@/shared/utils/helpers"
import { ContactActivityTriggerNodeType, ScheduleTriggerNodeType, TriggerNodeType } from "@/shared/models/workflow"
import { FiltersGroup } from "../../shared/models/filter"
import { useWorkflowStore } from "../../stores/workflow"

const workflowStore = useWorkflowStore()
interface Props {
    id?: string
    text?: string
    event?: string
    note?: { text?: string; lastEdited?: string }
    type?: string
    selectedData?: { name: string; label: string }[]
    showDeleteButton?: boolean
    enrolledFrom?: string[]
    branches?: { filtersGroups: FiltersGroup[] }[]
    firstValue?: string
    secondValue?: string
}
const props = withDefaults(defineProps<Props>(), { showDeleteButton: true })

const emits = defineEmits(["update:note", "removeNode", "openTriggerModal", "clickOnNode", "changeAction"])

const toolbarStatus = ref("emptyClosed")
const localNote = ref(props.note)
const isNoteVisible = ref(false)
const timeOfDay = ref()

const nodeTitle = computed(() => {
    if (props.enrolledFrom) {
        return "Manual"
    }
    if (props.type === TriggerNodeType.schedule) {
        return "Schedule"
    }
    return "Contact"
})

let hideTimeout: ReturnType<typeof setTimeout>
function handleMouseEnter() {
    clearTimeout(hideTimeout)
    isNoteVisible.value = true
}

function changeAction() {
    emits("changeAction", {
        componentName: EnrolmentTriggersSidebar.__name,
    })
}

function handleMouseLeave(event: MouseEvent) {
    hideTimeout = setTimeout(() => {
        if (
            ((event.relatedTarget as Element) && (event.relatedTarget as Element).classList.contains("toolbar-node")) ||
            toolbarStatus.value !== "emptyClosed" ||
            localNote.value?.text?.length !== 0
        ) {
            return
        }
        isNoteVisible.value = false
    }, 300)
}

function handleMouseEnterToolbarNode() {
    clearTimeout(hideTimeout)
    isNoteVisible.value = true
}

function updateNote(note: { text?: string; lastEdited?: string }) {
    emits("update:note", note)
    localNote.value = note
}

onMounted(() => {
    if (localNote.value?.text?.length !== 0) {
        isNoteVisible.value = true
    }
    if (workflowStore.scheduleTime) {
        timeOfDay.value = workflowStore.scheduleTime
    }
})
</script>
