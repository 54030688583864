import Crud from "./crud"
import { Config } from "@/shared/models/config"
import { Client } from "@/shared/services/client"
import { AgencyThemePayload } from "@/shared/models/agency-theme"

const client = new Client()

export class ConfigService extends Crud<Config> {
    constructor() {
        super("crm/config")
    }

    getConfig(): Promise<Config> {
        return client.get(this.endpoint)
    }

    getAgencyThemeBySubdomain(subdomain: string): Promise<AgencyThemePayload> {
        return client.get(`${this.endpoint}/${subdomain}`)
    }
}
