interface Palette {
    [key: number]: string
}

interface Rgb {
    r: number
    g: number
    b: number
}

const defaultShades = {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0090be",
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
} as Palette

function hexToRgb(hex: string): Rgb | null {
    const sanitizedHex = hex.replaceAll("##", "#")
    const colorParts = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sanitizedHex)

    if (!colorParts) {
        return null
    }

    const [, r, g, b] = colorParts

    return {
        r: parseInt(r, 16),
        g: parseInt(g, 16),
        b: parseInt(b, 16),
    } as Rgb
}

function rgbToHex(r: number, g: number, b: number): string {
    const toHex = (c: number) => `0${c.toString(16)}`.slice(-2)
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

function lighten(hex: string, intensity: number): string {
    const color = hexToRgb(`#${hex}`)

    if (!color) {
        return ""
    }

    const r = Math.round(color.r + (255 - color.r) * intensity)
    const g = Math.round(color.g + (255 - color.g) * intensity)
    const b = Math.round(color.b + (255 - color.b) * intensity)

    return rgbToHex(r, g, b)
}

function darken(hex: string, intensity: number): string {
    const color = hexToRgb(hex)

    if (!color) {
        return ""
    }

    const r = Math.round(color.r * intensity)
    const g = Math.round(color.g * intensity)
    const b = Math.round(color.b * intensity)

    return rgbToHex(r, g, b)
}

export function generateShades(baseColor: string): Palette {
    const response: Palette = {
        500: `#${baseColor}`.replace("##", "#"),
    }

    const intensityMap: {
        [key: number]: number
    } = {
        50: 0.95,
        100: 0.9,
        200: 0.75,
        300: 0.6,
        400: 0.3,
        600: 0.9,
        700: 0.75,
        800: 0.6,
        900: 0.49,
    }

    ;[50, 100, 200, 300, 400].forEach((level) => {
        response[level] = lighten(baseColor, intensityMap[level])
    })
    ;[600, 700, 800, 900].forEach((level) => {
        response[level] = darken(baseColor, intensityMap[level])
    })

    return response as Palette
}

export function setColorShades(cssProperty = "--primary-color", color?: string) {
    const root = document.documentElement
    const shades = color ? generateShades(color) : defaultShades
    if (root?.style) {
        Object.keys(shades)
            .map(Number)
            .forEach((shade) => {
                root.style.setProperty(`${cssProperty}-${shade}`, shades[shade])
            })
    }
}
