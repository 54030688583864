import { defineStore } from "pinia"
import { MessageTheme } from "@/shared/models/message-theme"
import { MessageThemeService } from "@/shared/services/message-theme"
import { ToastService } from "@/shared/services/toast"
import { splitCamelCase } from "@/shared/utils/helpers"

export const useMessageThemeStore = defineStore("messageTheme", {
    state: () => ({
        messageThemes: [] as MessageTheme[],
        messageThemeService: new MessageThemeService(),
        toastService: new ToastService(),
        isLoading: false,
    }),
    actions: {
        async getMessageTheme() {
            try {
                this.isLoading = true
                const response = await this.messageThemeService.getMessageTheme()
                if (response && response.themes) {
                    this.messageThemes = response.themes
                } else {
                    this.messageThemes = [] as MessageTheme[]
                }
            } finally {
                this.isLoading = false
            }
        },

        async updateMessageTheme(payload: Partial<MessageTheme>) {
            try {
                this.isLoading = true
                const response = await this.messageThemeService.updateMessageTheme(payload)
                const messageThemeIndex = this.messageThemes.findIndex(
                    //we have to check the channel because the ids could be different if the theme is inherited
                    (theme) => theme.channel === response.theme.channel
                )
                if (messageThemeIndex > -1) {
                    this.messageThemes[messageThemeIndex] = response.theme

                    this.toastService.addToast({
                        message: `${splitCamelCase(response.theme.channel)} updated successfully`,
                        type: "success",
                    })
                }
            } finally {
                this.isLoading = false
            }
        },
    },
})
