<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.85354 6.20711L5.14643 5.5L3.49999 7.14655L2.85354 6.5L2.14643 7.20711L3.49999 8.56066L5.85354 6.20711ZM2 18.8535C2 18.0335 2.67 17.3535 3.5 17.3535C4.33 17.3535 5 18.0335 5 18.8535C5 19.6735 4.32 20.3535 3.5 20.3535C2.68 20.3535 2 19.6735 2 18.8535ZM5.14643 11.5L5.85354 12.2071L3.49999 14.5607L2.14643 13.2071L2.85354 12.5L3.49999 13.1466L5.14643 11.5ZM21.5 19.8536H7.5V17.8535H21.5V19.8536ZM7.5 13.8535H21.5V11.8535H7.5V13.8535ZM7.5 7.85355V5.85355H21.5V7.85355H7.5Z"
            fill="#171B25"
        />
    </svg>
</template>
