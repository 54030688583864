<template>
    <GgmsModal :open="open" @closeModal="closeModal">
        <template #header>
            <div class="flex justify-flex-start items-center gap-x-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary-color-100">
                    <MailIcon class="h-6 w-6 text-primary-color-600"></MailIcon>
                </div>
                <h1 class="text-lg leading-6 font-medium text-gray-900">Please confirm your email</h1>
            </div>
        </template>
        <GgmsInfo :textCenter="false">
            <p>
                We've sent you an email. Please confirm your account by clicking on the verification link that we sent.
            </p>
        </GgmsInfo>
        <template #under>
            <div class="flex justify-end">
                <GgmsButton styleType="primary" @click="closeModal">OK</GgmsButton>
            </div>
        </template>
    </GgmsModal>
</template>

<script lang="ts" setup>
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsInfo from "@/components/GgmsInfo.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import { MailIcon } from "@heroicons/vue/outline"

interface Props {
    open: boolean
}

defineProps<Props>()

const emit = defineEmits(["closeModal"])

function closeModal() {
    emit("closeModal")
}
</script>
