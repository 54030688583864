import { defineStore } from "pinia"

export const useNavigationSidebarStore = defineStore("navigationSidebar", {
    state: () => ({
        isSidebarOpen: false,
    }),
    actions: {
        toggleSidebar(isOpen: boolean) {
            this.isSidebarOpen = isOpen
        },
    },
})
