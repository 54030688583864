<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">Time of day contacts should move to next action</p>
        <GgmsInput
            v-model="workflowStore.modelValue.time"
            label="Time of day"
            type="time"
            :iconStart="ClockIcon"
        ></GgmsInput>
        <GgmsRadioSelect v-model="workflowStore.modelValue.event" :options="radioButtonsOptions"></GgmsRadioSelect>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import { ClockIcon } from "@heroicons/vue/outline"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsRadioSelect from "@/components/GgmsRadioSelect.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter } from "@/shared/utils/helpers"

const workflowStore = useWorkflowStore()

const radioButtonsOptions = [
    { displayName: "Use account time zone (EST)", value: "account" },
    { displayName: "Use contact time zone", value: "contact" },
]

watch(
    () => workflowStore.modelValue.time,
    () => {
        //convert time into ISO date
        if (workflowStore.modelValue.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(workflowStore.modelValue.time)
        }
    }
)

watch(
    () => [workflowStore.modelValue.time, workflowStore.modelValue.event],
    () => {
        workflowStore.areButtonDisabled = !(workflowStore.modelValue.time && workflowStore.modelValue.event)
    }
)
</script>
