<template>
    <slot :toggle="toggleOverlayPanel" :visible="overlayPanel?.visible">
        <div @click="toggleOverlayPanel">Click me</div>
    </slot>
    <OverlayPanel ref="overlayPanel">
        <div class="max-w-[400px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="flex flex-col gap-y-3 px-4 py-3">
                <p v-if="title" class="text-base text-gray-700">{{ title }}</p>
                <GgmsInput
                    v-model="search"
                    :iconStart="SearchIcon"
                    placeholder="Search"
                    styleType="secondary"
                ></GgmsInput>
            </div>
            <div class="border-t border-gray-100 flex flex-col py-3 max-h-[210px] overflow-y-auto">
                <GgmsLoading v-if="isLoading"></GgmsLoading>
                <template v-else>
                    <template v-if="filteredOptions.length">
                        <!-- show selected option on top -->
                        <div v-if="getOptionText(selectedOption)" class="flex justify-between items-center px-6 py-1">
                            <span class="truncate">{{ getOptionText(selectedOption) }}</span>
                            <CheckIcon
                                class="h-5 w-5 min-h-[20px] min-w-[20px] text-primary-color-500"
                                aria-hidden="true"
                            />
                        </div>
                        <template v-for="(option, index) in filteredOptions">
                            <div
                                v-if="!isSelectedOption(option)"
                                :key="index"
                                class="flex gap-3 items-center px-6 py-1 cursor-pointer hover:bg-primary-color-100"
                                @click="selectOption(option)"
                            >
                                <span class="truncate">{{ getOptionText(option) }}</span>
                            </div>
                        </template>
                    </template>
                    <p v-else class="text-sm text-gray-700">No results found</p>
                </template>
            </div>
        </div>
    </OverlayPanel>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import { SearchIcon } from "@heroicons/vue/outline"
import { CheckIcon } from "@heroicons/vue/solid"

interface Props {
    modelValue?: any
    value?: any
    options: any[]
    multiple?: boolean
    optionAttribute?: string
    returnAttribute?: string
    title?: string
    isLoading?: boolean
}

const props = defineProps<Props>()
const emits = defineEmits(["update:modelValue", "onSelect"])

const overlayPanel = ref()
const search = ref("")
const filteredOptions = computed(() =>
    search.value === ""
        ? props.options
        : props.options.filter((option) =>
              getOptionText(option)
                  ?.toLowerCase()
                  .includes(search?.value?.toLowerCase() || "")
          )
)

const selectedOption = computed({
    get() {
        return props.modelValue || props.value
    },
    set(value) {
        emits("update:modelValue", value)
        emits("onSelect", value)
        overlayPanel.value.hide()
        search.value = ""
    },
})

function selectOption(option: any) {
    selectedOption.value = option
}

function getOptionValue(option: any) {
    return props.optionAttribute && props.returnAttribute ? option[props.returnAttribute] : option
}

function getOptionText(option: any) {
    return props.optionAttribute ? option[props.optionAttribute] : option
}

function toggleOverlayPanel(event: Event) {
    search.value = ""
    overlayPanel.value.toggle(event)
}

function isSelectedOption(option: any) {
    if (typeof option === "object") {
        return option._id === selectedOption.value._id
    }
    return option === selectedOption.value
}
</script>

<style scoped></style>
