<template>
    <div :class="classes">
        <p v-if="label" class="text-sm font-medium text-gray-700 mb-1">{{ label }}</p>
        <MultiSelect
            v-model="localModelValue"
            :options="options"
            :display="display"
            :showClear="isShowClear"
            :optionLabel="optionLabel"
            :optionValue="optionValue"
            :placeholder="placeholder"
            :maxSelectedLabels="maxSelectedLabels"
            :class="[errorStyle, classes]"
            :disabled="disabled"
            :emptyMessage="emptyMessage"
            :showToggleAll="false"
            @focus="disableValidationError"
        >
            <template #header>
                <GgmsCheckBox
                    :checked="isAllSelected"
                    :disabled="localDisabled"
                    :label="selectedText"
                    class="py-2 px-4"
                    @click="toggleAll"
                />
            </template>
            <template #itemcheckboxicon="slotProps">
                <GgmsCheckBox :checked="checkIfElementSelected(slotProps)" />
            </template>
            <template v-if="isChipDisplay" #value="slotProps">
                <GgmsChip
                    v-for="chip in slotProps.value"
                    :key="chip.id"
                    :bgColor="cachedTags[chip._id]?.backgroundColor ?? cachedOrigins[chip._id]?.backgroundColor"
                    :textColor="cachedTags[chip._id]?.foregroundColor ?? cachedOrigins[chip._id]?.foregroundColor"
                    class="mr-2"
                    @onRemove="removeChip(chip)"
                >
                    {{ chip.displayName }}
                </GgmsChip>
            </template>
            <template v-if="isChipDisplay" #option="slotProps">
                <GgmsChip
                    :bgColor="slotProps.option.backgroundColor"
                    :textColor="slotProps.option.foregroundColor"
                    :isRemovable="false"
                >
                    {{ slotProps.option.displayName }}
                </GgmsChip>
            </template>
        </MultiSelect>
        <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, computed, onMounted, ref, watch } from "vue"
import GgmsChip from "@/components/GgmsChip.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import { useTagStore } from "@/stores/tag"
import { useOriginStore } from "@/stores/origin"

const tagStore = useTagStore()
const originStore = useOriginStore()

interface Props {
    modelValue: any
    options: any[]
    display?: "comma" | "chip"
    label?: string
    optionLabel?: string
    optionValue?: string
    placeholder?: string
    isShowClear?: boolean
    classes?: string
    errorMessage?: string
    invalid?: boolean
    disabled?: boolean
    emptyMessage?: string
    maxSelectedLabels?: number
}

const props = withDefaults(defineProps<Props>(), {
    placeholder: "Select Element",
    display: "comma",
    maxSelectedLabels: 3,
})

const emit = defineEmits(["update:modelValue", "change"])

const localModelValue = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
})

const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)
const errorStyle = computed(() => (localInvalid.value ? "error-style" : ""))

const isChipDisplay = computed(() => props.display === "chip")
const isAllSelected = computed(() => localModelValue.value?.length === props.options?.length)
const selectedText = computed(() => (isAllSelected.value ? "Deselect All" : "Select All"))
const localDisabled = computed(() => props.disabled || !props.options?.length)

const cachedTags = computed(() => tagStore.cachedTags)
const cachedOrigins = computed(() => originStore.cachedOrigins)

function disableValidationError() {
    localInvalid.value = false
    localErrorMessage.value = ""
}

function removeChip(chip: any) {
    const index = localModelValue.value.findIndex((x: any) => x._id === chip._id)
    localModelValue.value.splice(index, 1)
}

function toggleAll() {
    localModelValue.value = isAllSelected.value ? [] : props.options
}

function checkIfElementSelected(option: any) {
    return option.checked
}

onMounted(() => {
    disableValidationError()
})

watch(
    () => props.errorMessage,
    (value) => {
        localErrorMessage.value = value
    }
)
</script>
