<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <rect
            x="2"
            y="13.5"
            width="20"
            height="5"
            rx="2"
            stroke="#171B25"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M3 6.5H14.5" stroke="#171B25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3 9.5H11" stroke="#171B25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
