<template>
    <div class="contact-selector-field">
        <ContactSelectorField
            :type="type"
            fieldName="to"
            :recipients="recipients"
            :isReply="isReply"
            :isForward="isForward"
            :from="from"
            :to="to"
            :offsetWidth="toField?.offsetWidth"
            :isWorkflow="isWorkflow"
            @addRecipient="addRecipient"
            @removeRecipient="removeRecipient"
            @closeContactSelect="showContactSelect = false"
        />
        <div v-if="!isMessageType && !isWorkflow" class="flex gap-x-2 ml-auto h-fit">
            <GgmsButton styleType="simple" :classes="['py-0 px-0 text-primary-color-600']" @click="toggleCC"
                >Cc</GgmsButton
            >
            <GgmsButton styleType="simple" :classes="['py-0 px-0 text-primary-color-600']" @click="toggleBCC"
                >Bcc</GgmsButton
            >
        </div>
    </div>

    <!-- CC -->
    <div v-if="isCCVisible || isCCOpen" class="contact-selector-field">
        <ContactSelectorField
            type="email"
            fieldName="cc"
            :recipients="emailCC"
            :offsetWidth="ccField?.offsetWidth"
            @addRecipient="addCCUser"
            @removeRecipient="removeCCUser"
            @closeContactSelect="showCCSelect = false"
        />
    </div>

    <!-- BCC -->
    <div v-if="isBCCVisible || isBCCOpen" class="contact-selector-field">
        <ContactSelectorField
            type="email"
            fieldName="bcc"
            :recipients="emailBCC"
            :offsetWidth="bccField?.offsetWidth"
            @addRecipient="addBCCUser"
            @removeRecipient="removeBCCUser"
            @closeContactSelect="showBCCSelect = false"
        />
    </div>

    <div v-if="isFromVisible" class="w-full flex items-center border-b pb-3">
        <span class="text-sm font-medium mr-2">From:</span>
        <ContactDropdown v-model:selectedSender="localSelectedSender" :senders="senders" :type="type" />
    </div>
    <GgmsLoading v-if="isLoading" />
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue"
import ContactDropdown from "@/components/profile-details/middle-panel/ContactDropdown.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import ContactSelectorField from "@/components/profile-details/middle-panel/ContactSelectorField.vue"
import { useConfigStore } from "@/stores/config"
import { useAgencyStore } from "@/stores/agency"
import { useIntegrationStore } from "@/stores/integration"
import { ToastService } from "@/shared/services/toast"
import { Integration } from "@/shared/models/integration"
import { Sender } from "@/shared/models/sender"
import { InboxContact } from "@/shared/models/inbox"
import { internationalPhoneFormat, getUser } from "@/shared/utils/helpers"

const configStore = useConfigStore()
const agencyStore = useAgencyStore()
const integrationStore = useIntegrationStore()
const toastService = new ToastService()

const props = defineProps({
    type: {
        type: String,
        default: "sms",
        validator(value: string) {
            return ["sms", "email"].includes(value)
        },
    },
    recipients: {
        type: Array as PropType<InboxContact[]>,
        default: () => [],
    },
    selectedSender: {
        type: Object as PropType<Sender>,
        default: () => ({} as Sender),
    },
    isReply: {
        type: Boolean,
        default: false,
    },
    isWorkflow: {
        type: Boolean,
        default: false,
    },
    isForward: {
        type: Boolean,
        default: false,
    },
    cc: {
        type: Array as PropType<InboxContact[]>,
        default: () => [],
    },
    bcc: {
        type: Array as PropType<InboxContact[]>,
        default: () => [],
    },
    to: {
        type: Array as PropType<string[]>,
        default: () => [],
    },
    from: {
        type: String,
        default: "",
    },
})

const emits = defineEmits(["update:recipients", "update:selectedSender", "update:cc", "update:bcc"])

const recipients = computed({
    get() {
        return props.recipients
    },
    set(value: InboxContact[]) {
        emits("update:recipients", value)
    },
})

const localSelectedSender = computed({
    get() {
        return props.selectedSender
    },
    set(value: Sender) {
        emits("update:selectedSender", value)
    },
})

const emailCC = computed({
    get() {
        return props.cc
    },
    set(value: InboxContact[]) {
        emits("update:cc", value)
    },
})

const emailBCC = computed({
    get() {
        return props.bcc
    },
    set(value: InboxContact[]) {
        emits("update:bcc", value)
    },
})

const toField = ref()
const ccField = ref()
const bccField = ref()
const showContactSelect = ref(false)
const showCCSelect = ref(false)
const showBCCSelect = ref(false)
const isLoading = ref(false)

const integrations = computed(() => configStore.integrations as Integration[])
const senders = ref<Sender[]>([])
const isMessageType = computed(() => props.type === "sms")
const isFromVisible = computed(() => senders.value.length && (isMessageType.value || !props.isReply))
const isCCVisible = ref(false)
const isBCCVisible = ref(false)

const isCCOpen = computed(() => emailCC.value.length > 0)
const isBCCOpen = computed(() => emailBCC.value.length > 0)

function toggleCC() {
    isCCVisible.value = !isCCVisible.value
}

function toggleBCC() {
    isBCCVisible.value = !isBCCVisible.value
}

function addRecipient(recipient: InboxContact) {
    // check if the recipient is already in the list by email or phone number
    const recipientEmailOrPhone = getRecipientEmailOrPhone(recipient)
    if (recipients.value.some((rec) => getRecipientEmailOrPhone(rec) === recipientEmailOrPhone)) {
        const warningMessage = isMessageType.value
            ? "This phone number is already in the list"
            : "This email address is already in the list"
        toastService.addToast({
            message: warningMessage,
            type: "warning",
        })
        return
    }
    recipients.value.push(recipient)

    showContactSelect.value = false
}

function removeRecipient(index: number) {
    recipients.value.splice(index, 1)
}

function addCCUser(cc: InboxContact) {
    // check if the CC recipient is already in the list by email
    if (emailCC.value.some((rec) => rec.email === cc.email)) {
        toastService.addToast({
            message: "This email address is already in the list",
            type: "warning",
        })
        return
    }
    emailCC.value.push(cc)

    showCCSelect.value = false
}

function removeCCUser(index: number) {
    emailCC.value.splice(index, 1)
}

function addBCCUser(bcc: InboxContact) {
    // check if the BCC recipient is already in the list by email
    if (emailBCC.value.some((rec) => rec.email === bcc.email)) {
        toastService.addToast({
            message: "This email address is already in the list",
            type: "warning",
        })
        return
    }
    emailBCC.value.push(bcc)

    showBCCSelect.value = false
}

function removeBCCUser(index: number) {
    emailBCC.value.splice(index, 1)
}

function getRecipientEmailOrPhone(recipient: InboxContact) {
    return isMessageType.value && recipient?.phone?.number
        ? internationalPhoneFormat(recipient?.phone?.number || "")
        : getRecipientEmail(recipient)
}

function getRecipientEmail(recipient: InboxContact) {
    if (!props.isReply || props.isForward) {
        return recipient?.email || ""
    }

    if (recipient.email !== props.from) {
        return props.to?.[0]
    }

    return props.from
}

async function setIntegrations() {
    await convertIntegrationsToSenders()
    setSelectedSender()
}

function getSendgridSenderProp(propName: string) {
    return integrationStore.integrationConfig?.versions
        ?.at(-1)
        ?.sectionAnswers?.[1]?.answers?.find((answer) => answer.name === propName)?.textAnswers?.[0]
}

async function convertIntegrationsToSenders() {
    integrations.value
        .filter((integration) => integration.integrationType === "inboxEmail" && integration.isEnabled)
        .forEach((integration) => {
            senders.value.push({
                name: integration?.fromName || "",
                email: integration.fromAddress,
                integrationName: integration.integrationName,
            })
        })

    // add Sendgrid integration to the list of senders if the it's enabled
    const sendgridIntegration = integrations.value.find((integration) => integration.integrationName === "sendgrid")
    if (!sendgridIntegration) {
        return
    }
    await integrationStore.getIntegration(sendgridIntegration._id)

    const sendgridSenderEmail = getSendgridSenderProp("item_5")
    const sendgridSenderName = getSendgridSenderProp("item_6")

    if (!sendgridSenderName || !sendgridSenderEmail) {
        return
    }
    senders.value.push({
        name: sendgridSenderName,
        email: sendgridSenderEmail,
        integrationName: sendgridIntegration.integrationName,
    })
}

function setSelectedSender() {
    if (!senders.value?.length) {
        toastService.addToast({
            message: "No email address found. Please add an email address in your profile settings.",
            type: "warning",
        })
        return
    }

    const selectedIndex = senders.value.findIndex((sender) => sender.email === localSelectedSender.value?.email)

    const senderIndex = selectedIndex === -1 ? 0 : selectedIndex
    localSelectedSender.value = senders.value[senderIndex]
}

function setOutboundPhoneNumbers() {
    const agent = getUser()
    if (agent?.outboundPhoneNumber) {
        senders.value.push({
            name: agent?.fullName || "",
            phone: agent?.outboundPhoneNumber,
        })
    }

    const agencyPhone = agencyStore.agency?.agencyOutboundPhoneNumber
    if (agencyPhone) {
        senders.value.push({
            name: agencyStore.agency?.agencyName || "",
            phone: agencyPhone,
        })
    }

    if (senders.value.length) {
        localSelectedSender.value = senders.value?.[0]
        return
    }
    toastService.addToast({
        message: "No phone number found. Please add a phone number in your profile settings.",
        type: "warning",
    })
}

onMounted(async () => {
    if (isMessageType.value) {
        setOutboundPhoneNumbers()
        return
    }
    try {
        isLoading.value = true
        if (!configStore.integrations.length) {
            await configStore.getConfig()
        }
        await setIntegrations()
    } finally {
        isLoading.value = false
    }
})
</script>

<style scoped>
.contact-selector-field {
    @apply flex gap-x-2 items-center w-full border-b pb-3;
}
</style>
