<template>
    <div v-if="activeTask" class="flex items-center justify-between py-3.5 px-8 bg-primary-color-600">
        <div class="flex items-center gap-x-3">
            <Avatar shape="circle" :label="fullNameInitiales" />
            <GgmsGeneralAgent
                :agent="activeTask.agent"
                class="!text-base text-white font-medium pr-3 border-r border-white"
            />
            <p class="text-white font-medium">Task Title: {{ activeTask.displayName }}</p>
        </div>
        <div class="flex gap-x-4">
            <GgmsButton styleType="primary" :iconStart="CalendarIcon" @click="toggleReschedulePanel">
                Reschedule
            </GgmsButton>
            <OverlayPanel ref="rescheduleOverlayPanel">
                <div
                    class="w-[330px] absolute z-50 -left-48 rounded-md shadow-lg bg-white p-4 ring-1 ring-black ring-opacity-5"
                >
                    <p class="text-sm text-gray-900 mb-1">Current Due Date</p>
                    <p class="text-sm text-gray-500 mb-4">{{ activeTaskDate }}</p>
                    <p class="text-sm text-gray-700 mb-1">New Due Date</p>
                    <div class="flex justify-between mb-4">
                        <GgmsInput
                            v-model="newTaskDate"
                            class="w-[150px]"
                            type="date"
                            :small="true"
                            :iconStart="CalendarIcon"
                        />
                        <GgmsInput
                            v-model="newTaskTime"
                            class="w-[132px]"
                            type="time"
                            :small="true"
                            :iconStart="ClockIcon"
                        />
                    </div>
                    <div class="flex gap-4">
                        <!-- Padding has to be added separately, because it's defined in the same way in the child component -->
                        <GgmsButton styleType="simple" :classes="['py-0 font-normal px-0']" @click="rescheduleTask"
                            >Save</GgmsButton
                        >
                        <GgmsButton
                            styleType="simple"
                            :classes="['text-gray-700 font-normal py-0 px-0']"
                            @click="toggleReschedulePanel"
                            >Cancel</GgmsButton
                        >
                    </div>
                </div>
            </OverlayPanel>
            <GgmsButton
                v-if="isPreviousActive"
                styleType="primary"
                :iconStart="RewindIcon"
                @click="goBackToPreviousTask"
                >Previous
            </GgmsButton>
            <GgmsButton v-if="isNextActive" styleType="primary" :iconStart="FastForwardIcon" @click="goToNextTask"
                >Next</GgmsButton
            >
            <GgmsButton styleType="primary" :iconStart="CheckCircleIcon" @click="completeTask">Complete</GgmsButton>
            <div class="flex flex-col items-center justify-center">
                <p class="text-sm text-white">Progress</p>
                <p class="text-sm font-semibold text-white">{{ activeTaskIndex + 1 }}/{{ activeTasks.length }}</p>
            </div>
            <button class="bg-primary-color-600 p-2" @click="taskStore.closeTaskSession">
                <XIcon class="w-6 h-6 stroke-white" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsGeneralAgent from "@/components/GgmsGeneralAgent.vue"
import { CalendarIcon, RewindIcon, FastForwardIcon, CheckCircleIcon, XIcon, ClockIcon } from "@heroicons/vue/outline"
import { useTaskStore } from "@/stores/task"
import { Task } from "@/shared/models/task"
import { getInitials, formatHumanReadableDate, getDateInputFormat, getTimeInputFormat } from "@/shared/utils/helpers"
import { ToastService } from "@/shared/services/toast"

const taskStore = useTaskStore()
const toastService = new ToastService()

const activeTasks = computed(() => taskStore.activeTasks)
const activeTask = computed(() => taskStore.activeTask)

const activeTaskIndex = computed(() => activeTasks.value.findIndex((task) => task._id === activeTask.value?._id))
const fullNameInitiales = computed(() => getInitials(activeTask?.value?.agent?.fullName || "G G"))
const isPreviousActive = computed(() => getPreviousPendingTask() !== -1)
const isNextActive = computed(() => getNextPendingTask() !== -1)

const activeTaskDate = computed(() => formatHumanReadableDate(new Date(activeTask.value.when?.startAt || ""), false))
const newTaskDate = ref(getDateInputFormat(new Date()))
const newTaskTime = ref(getTimeInputFormat(new Date()))

const rescheduleOverlayPanel = ref()

function toggleReschedulePanel(event?: Event) {
    rescheduleOverlayPanel.value.toggle(event)
}

function setTaskStartDate(): string {
    const date = new Date(newTaskDate.value)
    const hours = Number(newTaskTime.value.split(":")[0])
    const minutes = Number(newTaskTime.value.split(":")[1])
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes).toISOString()
}

async function rescheduleTask() {
    const updatedActiveTask = { ...activeTask.value }
    updatedActiveTask.when.startAt = setTaskStartDate()
    delete updatedActiveTask.agent
    updatedActiveTask.agentId = activeTask.value.agent?._id

    await taskStore.updateTask(activeTask.value._id || "", updatedActiveTask)

    toggleReschedulePanel()
    toastService.addToast({
        type: "success",
        message: "Task rescheduled.",
    })

    await Promise.all([taskStore.getTasks(), taskStore.getActiveTaskSession()])

    const currentTaskIndex = taskStore.tableState.data.findIndex((task) => task._id === activeTask.value._id)
    const tasksAfterCurrent = taskStore.tableState.data.slice(currentTaskIndex + 1)
    const pendingTasksAfterCurrent = tasksAfterCurrent.filter((task) => task.status === "pending")

    if (pendingTasksAfterCurrent.length === 0) {
        goToFirstPendingTask()
    }

    if (activeTask.value.status === "completed") {
        goToNextTask()
    }
}

async function completeTask() {
    taskStore.activeTask.status = "completed"
    const completedTask: Partial<Task> = { ...activeTask.value }
    delete completedTask.agent
    delete completedTask._id
    completedTask.agentId = activeTask.value.agent?._id

    await taskStore.updateTask(activeTask.value._id || "", completedTask as Task)

    toastService.addToast({
        type: "success",
        message: "Task successfully completed!",
    })

    if (taskStore.checkIfAllActiveTasksCompleted()) {
        await taskStore.closeTaskSession()
    }
    goToPendingTask()
}

function goToPendingTask() {
    isNextActive.value ? goToNextTask() : goToFirstPendingTask()
}

function goToFirstPendingTask() {
    const firstPendingTask = activeTasks.value.find((task) => task.status === "pending")
    if (firstPendingTask) {
        taskStore.activeTask = firstPendingTask
    }
}

function goBackToPreviousTask() {
    const previousPendingTaskIndex = getPreviousPendingTask()
    if (previousPendingTaskIndex !== -1) {
        taskStore.activeTask = activeTasks.value[previousPendingTaskIndex]
    }
}

function getPreviousPendingTask(): number {
    return activeTasks.value
        .map((task, index) => task.status === "pending" && index < activeTaskIndex.value)
        .lastIndexOf(true)
}

async function goToNextTask() {
    const nextPendingTaskIndex = getNextPendingTask()
    if (nextPendingTaskIndex !== -1) {
        taskStore.activeTask = activeTasks.value[nextPendingTaskIndex]
    }
}

function getNextPendingTask(): number {
    return activeTasks.value.findIndex((task, index) => task.status === "pending" && index > activeTaskIndex.value)
}

onMounted(async () => {
    await taskStore.getActiveTaskSession()

    if (!activeTask.value?._id) {
        await taskStore.closeTaskSession()
    }
})

watch(activeTaskIndex, () => {
    sessionStorage.setItem("savedActiveTaskIndex", activeTaskIndex.value.toString())
})
</script>
