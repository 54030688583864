import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { AgencyTheme } from "../models/agency-theme"
import { ResponsePresignedUrl, UploadItem } from "../models/upload-file"

const client = new Client()

export class AgencyThemeService extends Crud<AgencyTheme> {
    constructor() {
        super("crm/themes")
    }

    getAgencyTheme(): Promise<AgencyTheme> {
        return client.get(this.endpoint)
    }

    updateAgencyTheme(body: AgencyTheme): Promise<{ theme: AgencyTheme }> {
        return client.put(this.endpoint, body)
    }

    requestPostDataForFileUpload(uploads: UploadItem[]): Promise<ResponsePresignedUrl> {
        return client.post(`${this.endpoint}/files`, { uploads })
    }

    uploadFiles(endpoint: string, data: FormData) {
        return client.postFile(endpoint, data)
    }
}
