<template>
    <div class="flex flex-col gap-y-6">
        <p class="text-lg leading-7 font-medium text-black">Days of the week contacts should move to next action</p>
        <GgmsMultiSelect
            v-model="workflowStore.modelValue.days"
            :options="daysOptions"
            placeholder="Choose day or days"
            classes="w-full"
        ></GgmsMultiSelect>
        <div class="flex flex-col">
            <p class="text-sm text-gray-800 font-medium">Time of day</p>
            <GgmsRadioSelect v-model="workflowStore.modelValue.event" :options="radioButtonsOptions"></GgmsRadioSelect>
            <GgmsInput
                v-if="workflowStore.modelValue.event === CalendarNodeType.time"
                v-model="workflowStore.modelValue.time"
                class="pt-4"
                label="Select time"
                type="time"
                :iconStart="ClockIcon"
            ></GgmsInput>
        </div>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import GgmsMultiSelect from "@/components/GgmsMultiSelect.vue"
import GgmsRadioSelect from "@/components/GgmsRadioSelect.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { CalendarNodeType } from "@/shared/models/workflow"

const workflowStore = useWorkflowStore()

const daysOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

const radioButtonsOptions = [
    { displayName: "Delay until the same time of day that the contact entered the delay", value: "now" },
    { displayName: "Specific time", value: "time" },
]

watch(
    () => workflowStore.modelValue.event,
    () => {
        if (workflowStore.modelValue.event !== "time") {
            workflowStore.modelValue.time = ""
        }
    }
)

watch(
    () => [workflowStore.modelValue.days, workflowStore.modelValue.event, workflowStore.modelValue.time],
    () => {
        workflowStore.areButtonDisabled =
            workflowStore.modelValue.days &&
            workflowStore.modelValue.event &&
            workflowStore.modelValue.event === "time" &&
            workflowStore.modelValue.time
                ? false
                : !(
                      workflowStore.modelValue.days &&
                      workflowStore.modelValue.event &&
                      workflowStore.modelValue.event !== "time"
                  )
    }
)
</script>
