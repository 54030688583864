<template>
    <div :class="[getWrapperStyle()]">
        <h3 v-if="hasTitle()" class="text-lg leading-6 font-medium text-gray-900">
            <slot name="title" />
        </h3>
        <div class="text-sm text-gray-500 mt-2">
            <slot />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useSlots, withDefaults } from "vue"

const slots = useSlots()

function hasTitle() {
    return !!slots.title
}

interface Props {
    textCenter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    textCenter: true,
})

function getWrapperStyle() {
    return props.textCenter ? "text-center" : ""
}
</script>
