<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#E5E7EB" />
        <path
            d="M12 5.62848C12.6108 4.93646 13.5044 4.5 14.5 4.5C16.341 4.5 17.8333 5.99238 17.8333 7.83333C17.8333 9.67428 16.341 11.1667 14.5 11.1667C13.5044 11.1667 12.6108 10.7302 12 10.0382M14.5 19.5H4.5V18.6667C4.5 15.9052 6.73858 13.6667 9.5 13.6667C12.2614 13.6667 14.5 15.9052 14.5 18.6667V19.5ZM14.5 19.5H19.5V18.6667C19.5 15.9052 17.2614 13.6667 14.5 13.6667C13.5893 13.6667 12.7354 13.9102 12 14.3356M12.8333 7.83333C12.8333 9.67428 11.341 11.1667 9.5 11.1667C7.65905 11.1667 6.16667 9.67428 6.16667 7.83333C6.16667 5.99238 7.65905 4.5 9.5 4.5C11.341 4.5 12.8333 5.99238 12.8333 7.83333Z"
            stroke="#374151"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
