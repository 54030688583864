import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { MessageTheme } from "../models/message-theme"

const client = new Client()

export class MessageThemeService extends Crud<MessageTheme> {
    constructor() {
        super("crm/themes")
    }

    getMessageTheme(): Promise<{ themes: MessageTheme[] }> {
        return client.get(this.endpoint)
    }

    updateMessageTheme(body: Partial<MessageTheme>): Promise<{ theme: MessageTheme }> {
        return client.put(this.endpoint, body)
    }
}
