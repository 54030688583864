<template>
    <div>
        <div class="text-base leading-6 font-medium text-black flex gap-x-1">
            <p v-if="workflowStore.isEnrollWorkflow">Enrolled in</p>
            <p v-else>Unenrolled from</p>
            one of the workflows:
        </div>
        <div id="searchOverlayTarget">
            <GgmsInput
                v-model="search"
                class="w-full mt-4"
                :iconStart="SearchIcon"
                placeholder="Search"
                @input="toggleSearchMenu"
                @click="toggleSearchMenu"
            ></GgmsInput>
            <OverlayPanel
                ref="searchMenu"
                class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                appendTo="#searchOverlayTarget"
            >
                <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                    <div class="relative">
                        <div v-if="!selectedWorkflows?.length" class="py-6 border-0 px-4">No results found</div>
                        <template v-else>
                            <div
                                v-for="workflow in selectedWorkflows"
                                :key="workflow._id"
                                class="flex items-center gap-x-2 px-4 py-3 cursor-pointer border-b border-gray-200 last:border-0"
                                tabindex="0"
                            >
                                <GgmsCheckBox :checked="isElementChecked(workflow)" @click="onClick(workflow)">
                                    <div
                                        class="ml-3 min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex"
                                    >
                                        {{ workflow?.name }}
                                    </div>
                                </GgmsCheckBox>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearching" />
                    </div>
                </div>
                <div
                    v-if="workflowStore.selectedData.length"
                    class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                >
                    <div class="flex items-center gap-x-4">
                        <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearData">
                            <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                        </div>
                        <p class="text-base leading-6 font-medium w-fit">
                            {{ workflowStore.selectedData.length }} selected -
                            <span
                                class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                @click="onSelect"
                            >
                                {{ selectAllText }}
                            </span>
                        </p>
                    </div>
                    <GgmsButton @click="closeSearchMenu">Done</GgmsButton>
                </div>
            </OverlayPanel>
            <div v-if="showData" class="flex flex-col pt-3">
                <div
                    v-for="workflow in workflowStore.selectedData"
                    :key="workflow._id"
                    class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
                >
                    <div class="min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex">
                        {{ workflow?.name }}
                    </div>
                    <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteData(workflow)"></TrashIcon>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { MinusSmIcon, SearchIcon, TrashIcon } from "@heroicons/vue/outline"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { Workflow } from "@/shared/models/workflow"
import { debounce } from "@/shared/utils/helpers"

const workflowStore = useWorkflowStore()

const searchMenu = ref()
const search = ref("")
const isSearching = ref(false)
const isAllSelected = ref(false)
const total = ref(0)
const showData = ref(false)

const selectedWorkflows = ref<Workflow[]>([])
const data = ref<Workflow[]>([])

const selectAllText = computed(() => (isAllSelected.value ? "Deselect all" : `Select all (${total.value})`))

function toggleSearchMenu(event?: Event) {
    searchMenu.value.show(event)
}

function clearData() {
    workflowStore.selectedData = []
}

function onSelect() {
    if (isAllSelected.value) {
        clearData()
    } else {
        workflowStore.selectedData = selectedWorkflows.value
    }
    isAllSelected.value = !isAllSelected.value
}

function closeSearchMenu() {
    showData.value = true
    searchMenu.value.hide()
}

function isElementChecked(element: Workflow) {
    return !!workflowStore.selectedData.find((el) => el._id === element._id)
}

function onClick(element: Workflow) {
    if (isElementChecked(element)) {
        const index = workflowStore.selectedData.findIndex((el) => el._id === element._id)
        workflowStore.selectedData.splice(index, 1)
        return
    }

    workflowStore.selectedData.push(element)
}

function deleteData(element: Workflow) {
    workflowStore.selectedData.splice(workflowStore.selectedData.indexOf(element), 1)
}

watch(
    () => search.value,
    debounce(async () => {
        if (workflowStore.workflow._id) {
            selectedWorkflows.value = await workflowStore.autocomplete(search.value, workflowStore.workflow._id)
        } else {
            selectedWorkflows.value = await workflowStore.autocomplete(search.value)
        }
    }, 250)
)

watch(
    () => workflowStore.selectedData,
    () => {
        workflowStore.areButtonDisabled = !workflowStore.selectedData.length
    },
    { deep: true }
)

onMounted(async () => {
    if (workflowStore.workflow._id) {
        selectedWorkflows.value = await workflowStore.autocomplete(search.value, workflowStore.workflow._id)
    } else {
        selectedWorkflows.value = await workflowStore.autocomplete(search.value)
    }
    if (workflowStore.selectedData.length) {
        showData.value = true
    }

    total.value = selectedWorkflows.value.length
})
</script>
