<template>
    <div class="relative">
        <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t" :class="color" />
        </div>
        <div :class="['relative flex text-sm', separatorTextAlignment, textDivClass]">
            <span :class="[textStyle, 'px-2 text-gray-500']">{{ text }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

interface Props {
    color?: string
    text?: string
    textAlignment?: "start" | "center" | "end"
    textDivClass?: string
    textStyle?: string
}

const props = withDefaults(defineProps<Props>(), {
    color: "border-gray-300",
    textAlignment: "center",
    textStyle: "bg-white",
})

const separatorTextAlignment = computed(() => `justify-${props.textAlignment}`)
</script>
