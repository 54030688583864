import { Client } from "@/shared/services/client"
import Crud from "@/shared/services/crud"
import { Origin } from "@/shared/models/origin"
import { Job } from "@/shared/models/jobs"

const client = new Client()

export class OriginService extends Crud<Origin> {
    constructor() {
        super("crm/origin")
    }

    createOrigin(origin: Origin): Promise<{ job: Job }> {
        return client.post(`${this.endpoint}`, origin)
    }

    updateOrigin(id: string, origin: Partial<Origin>): Promise<{ origin: Origin }> {
        return client.patch(`${this.endpoint}/${id}`, origin)
    }

    deleteOrigin(id: string): Promise<void> {
        return client.patch(`${this.endpoint}/delete/${id}`)
    }

    bulkDeleteOrigin(ids: string[], all: boolean): Promise<void> {
        return client.post(`${this.endpoint}/bulk/delete`, { ids, all })
    }

    getCachedOrigins(): Promise<{ data: Origin[] }> {
        return client.get(`${this.endpoint}/all`)
    }
}
