<template>
    <a :href="props.to">
        <img :src="logo" alt="ggms-logo" :class="['max-h-[200px] max-w-[448px] bg-cover', logoClass]" />
    </a>
</template>

<script lang="ts" setup>
import { computed, withDefaults } from "vue"
import GgmsLogo from "@/assets/icons/GGMS.svg"
import { useAgencyThemeStore } from "@/stores/agency-theme"

const agencyThemeStore = useAgencyThemeStore()

interface Props {
    to?: string
    logoClass?: string
}

const props = withDefaults(defineProps<Props>(), {
    to: "/",
})

const logo = computed(() => agencyThemeStore.agencyTheme?.logo?.url || GgmsLogo)
</script>
