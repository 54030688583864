import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Email, EmailPayload } from "../models/email"
import { ResponsePresignedUrl, UploadItem } from "../models/upload-file"
import { Inbox } from "../models/inbox"

const client = new Client()

export class EmailService extends Crud<Email> {
    constructor() {
        super("/crm/messaging/email")
    }

    createEmail(email: EmailPayload): Promise<{ threads: Inbox[] }> {
        return client.post(this.endpoint, email)
    }

    updateEmail(emailId: string, email: Partial<Email>): Promise<Email> {
        return client.put(`${this.endpoint}/${emailId}`, email)
    }

    cancelEmail(emailId: string): Promise<Email> {
        return client.delete(`${this.endpoint}/${emailId}`)
    }

    requestPostDataForFileUpload(uploads: UploadItem[]): Promise<ResponsePresignedUrl> {
        return client.post(`${this.endpoint}/files`, { uploads })
    }

    uploadFiles(endpoint: string, data: FormData) {
        return client.postFile(endpoint, data)
    }
}
