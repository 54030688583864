import { defineStore } from "pinia"
import { Attribute } from "@/shared/models/attributes"
import { AttributesService } from "@/shared/services/attributes"
import { Property } from "@/shared/models/property"

export const useAttributesStore = defineStore("attributes", {
    state: () => ({
        attributesService: new AttributesService(),
        attributes: [] as Attribute[],
        totalAttributes: 0,
    }),
    actions: {
        async getAttributes(typeName: string) {
            const response = await this.attributesService.getAll({ typeName })
            this.attributes = response.data
            this.totalAttributes = response.total
        },

        async createAttribute(data: Property, type: string) {
            await this.attributesService.createAttribute(data, type)
        },
    },
    getters: {
        getAttributesForPropValueChange(): Attribute[] {
            return this.attributes.filter((attr) => {
                return attr.name !== "_id" && attr.name !== "firstName" && attr.name !== "lastName"
            })
        },
    },
})
