<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7941_67089)">
            <path
                d="M6.73099 3.56867C6.87099 3.48267 7.01633 3.40467 7.16699 3.33333C7.16699 2.97971 7.30747 2.64057 7.55752 2.39052C7.80757 2.14048 8.1467 2 8.50033 2C8.85395 2 9.19309 2.14048 9.44313 2.39052C9.69318 2.64057 9.83366 2.97971 9.83366 3.33333C10.5993 3.69535 11.2519 4.25888 11.7217 4.96353C12.1915 5.66818 12.4606 6.48738 12.5003 7.33333V9.33333M11.8337 11.3333H3.16699C3.53028 11.1276 3.8402 10.8395 4.07178 10.4921C4.30337 10.1447 4.45016 9.7478 4.50033 9.33333V7.33333C4.54196 6.44552 4.83641 5.58809 5.34899 4.862"
                stroke="#92400E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.5 11.333V11.9997C6.5 12.5301 6.71071 13.0388 7.08579 13.4139C7.46086 13.789 7.96957 13.9997 8.5 13.9997C9.03043 13.9997 9.53914 13.789 9.91421 13.4139C10.2893 13.0388 10.5 12.5301 10.5 11.9997V11.333"
                stroke="#92400E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.5 2L14.5 14"
                stroke="#92400E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_7941_67089">
                <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
</template>
