<template>
    <GgmsInput v-model="originStore.tableState.search" placeholder="Search values" :iconStart="SearchIcon" />

    <div class="px-4 py-4.5 border border-gray-200 shadow sm:rounded-lg h-52 overflow-auto">
        <GgmsLoading v-if="originStore.tableState.isLoading" />
        <template v-else>
            <div v-for="(origin, i) in notHiddenOrigins" :key="origin._id" class="flex items-center mb-5 last:mb-0">
                <GgmsCheckBox v-model="localOriginsSelectedState[i]" :indeterminate="originsSelectedState[i] === null">
                    <GgmsChip
                        :bgColor="origin.backgroundColor"
                        :textColor="origin.foregroundColor"
                        :isRemovable="false"
                        class="ml-2"
                        >{{ origin.displayName }}</GgmsChip
                    >
                </GgmsCheckBox>
            </div>

            <p v-if="!notHiddenOrigins.length" class="text-gray-500 mb-4">
                {{ getNotFoundText("origins", originStore.tableState.search) }}
            </p>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import { useOriginStore } from "@/stores/origin"
import { debounce, getNotFoundText } from "@/shared/utils/helpers"
import { SearchIcon } from "@heroicons/vue/outline"

interface Props {
    originsSelectedState: boolean[]
    hasFilterOrigins: boolean
    typeName: string
}

const props = defineProps<Props>()
const emit = defineEmits(["onOriginSelect"])

const originStore = useOriginStore()

const localOriginsSelectedState = ref<boolean[]>(props.originsSelectedState)

const origins = computed(() => originStore.tableState.data)
const notHiddenOrigins = computed(() => originStore.tableState.data.filter((origin) => !origin.isHidden))

function createFilterOrigin() {
    // get the selected origins ids which are selected in localOriginsSelectedState
    const selectedOriginsIds = localOriginsSelectedState.value
        .map((selected, index) => (selected ? origins.value[index]._id : null))
        .filter((id) => id !== null)

    emit("onOriginSelect", selectedOriginsIds)
}

onUnmounted(() => {
    originStore.tableState.search = ""
})

watch(
    () => originStore.tableState.search,
    debounce(() => {
        if (props.hasFilterOrigins) {
            // this is to prevent the search from being triggered when the modal is closed
            return
        }
        originStore.tableState.currentPage = 0
        originStore.getOrigins()
    }, 250)
)

watch(
    () => localOriginsSelectedState.value,
    () => {
        createFilterOrigin()
    },
    { deep: true }
)

watch(
    () => props.originsSelectedState,
    () => {
        localOriginsSelectedState.value = props.originsSelectedState
    },
    { deep: true }
)
</script>
```
