import { defineStore } from "pinia"
import { MessageService } from "@/shared/services/message"
import { Message } from "@/shared/models/message"
import { ToastService } from "@/shared/services/toast"
import { useTimelineStore } from "./timeline"
import { Error } from "@/shared/models/error"
import { useConfigStore } from "./config"
import { useLeadsStore } from "./leads"
import { Config } from "@/shared/models/config"
import { IntegrationName } from "@/shared/models/integration"

export const useMessageStore = defineStore("message", {
    state: () => ({
        messageService: new MessageService(),
        toastService: new ToastService(),
        isLoading: false,
        timelineStore: useTimelineStore(),
        validationError: {} as Error,
        configStore: useConfigStore(),
        leadsStore: useLeadsStore(),
        config: {} as Config,
        timelineMessageTextAreaRef: {} as HTMLTextAreaElement,
    }),
    actions: {
        async createMessage(message: Message, leadId?: string) {
            try {
                this.isLoading = true
                const res = await this.messageService.createMessage(message)
                if (res) {
                    const toastMessage = message?.scheduledAt
                        ? "The text message has been scheduled successfully"
                        : "The text message has been sent successfully"
                    this.toastService.addToast({
                        message: toastMessage,
                        type: "success",
                    })

                    if (leadId) {
                        this.timelineStore.getTimeline(leadId)
                    }
                    this.decreaseMessageCount()

                    return res.threads[0]
                }
            } catch (error) {
                const err = error as Error
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async updateMessage(messageId: string, message: Partial<Message>) {
            try {
                this.isLoading = true
                const res = await this.messageService.updateMessage(messageId, message)
                if (res) {
                    this.toastService.addToast({
                        message: "Text message was updated",
                        type: "success",
                    })
                    this.timelineStore.getTimeline(this.leadsStore.lead._id)
                }
            } catch (error) {
                const err = error as Error
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async cancelMessage(messageId: string, isDelete?: boolean) {
            try {
                this.isLoading = true
                const res = await this.messageService.cancelMessage(messageId)
                if (res) {
                    this.toastService.addToast({
                        message: isDelete
                            ? "Text message has been deleted"
                            : "Sending text message successfully canceled",
                        type: "success",
                    })
                    this.timelineStore.getTimeline(this.leadsStore.lead._id)
                }
            } catch (error) {
                const err = error as Error
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async getMessagingReply(token: string) {
            try {
                this.isLoading = true
                this.config = await this.messageService.getMessagingReply(token)
            } catch (error) {
                const err = error as Error
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        async replyToMessage(body: string, token: string) {
            try {
                this.isLoading = true
                await this.messageService.replyToMessage(body, token)
                this.toastService.addToast({
                    message: "The reply text message has been sent successfully",
                    type: "success",
                })
            } catch (error) {
                const err = error as Error
                if (err.code === "ValidationError") {
                    this.validationError = err
                }
            } finally {
                this.isLoading = false
            }
        },

        decreaseMessageCount() {
            const twilioIndex = this.configStore.integrations.findIndex(
                (i) => i.integrationName === IntegrationName.twilio
            )
            const integration = this.configStore.integrations[twilioIndex]
            if (integration?.remainingDailySentMessagesLimit && integration?.remainingDailySentMessagesThreshold) {
                integration.remainingDailySentMessagesLimit--
                integration.remainingDailySentMessagesThreshold--
            }
        },
    },
})
