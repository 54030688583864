<template>
    <div :class="[inline ? '' : 'flex-col', 'flex']">
        <label :for="id" :class="[disabled ? 'opacity-40' : '', 'block mb-1 text-sm font-medium text-gray-700']">{{
            label
        }}</label>
        <div class="relative w-full">
            <flat-pickr
                v-model="inputValue"
                :config="config"
                :id="id"
                class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none placeholder-gray-400 focus:ring-primary-color-500 focus:border-primary-color-500 border-gray-300 text-base"
            >
            </flat-pickr>
            <!-- Overlay for readonly as flatpickr doesn't support it by default -->
            <div v-if="disabled" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-60"></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { formatDateToIso } from "@/shared/utils/helpers"

interface Props {
    position?: string
    modelValue: string | Date | undefined
    showTime?: boolean
    showDate?: boolean
    label?: string
    id?: string
    disabled?: boolean
    inline?: boolean
    minDate?: string
}

const props = withDefaults(defineProps<Props>(), {
    inline: true,
})
const emit = defineEmits(["update:modelValue"])

let internalValue = ""

const config = ref({
    position: props.position,
    enableTime: props.showTime,
    noCalendar: !props.showDate,
    minDate: props.minDate,
})

const inputValue = computed({
    get: () => {
        if (props.modelValue !== internalValue) {
            internalValue = String(props.modelValue) ?? ""
            if (props.modelValue instanceof Date && !isNaN(props.modelValue.getTime())) {
                const date = new Date(props.modelValue)
                const newDate = new Date(date.getTime())
                return newDate.toISOString().slice(0, -1)
            } else if (typeof props.modelValue === "string") {
                return formatDateToIso(props.modelValue).slice(0, -1)
            }
        }
        return internalValue
    },
    set: (value: string | Date) => {
        internalValue = String(value)
        emit("update:modelValue", value)
    },
})
</script>
