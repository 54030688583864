import { defineStore } from "pinia"
import { useJobsStore } from "@/stores/jobs"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { SocketClientWrapper } from "@/shared/services/socket"
import { ToastService } from "@/shared/services/toast"
import { JobPayload, Job } from "@/shared/models/jobs"
import { ToastModel } from "@/shared/models/toast-model"
import { TaskNotificationReminder } from "@/shared/models/task"

export const useSocketStore = defineStore("socket", {
    state: () => ({
        socketService: {} as SocketClientWrapper,
        inProgressJobs: {} as Record<string, Job>,
        taskReminders: {} as Record<string, TaskNotificationReminder>,
        toastService: new ToastService(),
        jobStore: useJobsStore(),
        gridCollectionStore: useGridCollectionStore(),
    }),
    actions: {
        connectSocket() {
            this.socketService = SocketClientWrapper.getInstance(`${process.env.VUE_APP_BASE_API_URL}/jobs`)
            this.socketService.connect()
            this.socketService.on("connect", () => {
                this.socketService.emit("join")
            })
        },

        connectJobs() {
            this.socketService.on("taskReminder", (tasks: { taskDetails: TaskNotificationReminder }[]) => {
                tasks.forEach((item: { taskDetails: TaskNotificationReminder }) => {
                    const { taskDetails: task } = item
                    this.taskReminders[task._id] = task
                    const toastPayload = {
                        type: "notification",
                        message: task.title,
                        persistent: true,
                        id: task._id,
                    } as ToastModel

                    const existingToast = this.toastService.getToasts().find((toast) => toast.id === task._id)
                    if (existingToast) {
                        this.toastService.updateToast(task._id, toastPayload)
                    } else {
                        this.toastService.addToast(toastPayload)
                    }
                })
            })
            this.socketService.on("updatedJob", (jobs: JobPayload[]) => {
                jobs.forEach(({ job }) => {
                    if (!job || job.progress === undefined) {
                        return
                    }

                    this.inProgressJobs[job._id] = job
                    this.jobStore.tableState.data.forEach((iterator) => {
                        if (iterator._id === job._id) {
                            iterator.progress = job.progress
                        }
                    })

                    const toastPayload = {
                        type: "notification",
                        message: job.title,
                        persistent: true,
                        percentage: job.progress,
                        id: job._id,
                    } as ToastModel

                    const existingToast = this.toastService.getToasts().find((toast) => toast.id === job._id)
                    if (existingToast) {
                        this.toastService.updateToast(job._id, toastPayload)
                    } else if (job.progress < 100) {
                        this.toastService.addToast(toastPayload)
                    }

                    if (job.progress === 100) {
                        delete this.inProgressJobs[job._id]
                        if (job?.title?.toLowerCase().includes("leads")) {
                            this.jobStore.jobFinishedForEntity = "lead"
                        }
                        if (job?.title?.toLowerCase().includes("tasks")) {
                            this.jobStore.jobFinishedForEntity = "task"
                        }
                        if (job?.title?.toLowerCase().includes("listings")) {
                            this.jobStore.jobFinishedForEntity = "listing"
                        }
                        if (job?.title?.toLowerCase().includes("forms")) {
                            this.jobStore.jobFinishedForEntity = "form"
                        }
                    }
                })
            })
        },

        disconnectSocket() {
            this.socketService.disconnect()
        },

        addJob(job: Job) {
            this.inProgressJobs[job._id] = job
        },
    },
})
