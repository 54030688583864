<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Separator">
            <path
                id="Vector 1"
                d="M2 12.5L22 12.5"
                stroke="#171B25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="Vector 3"
                d="M3 10.5H2V14.5H3"
                stroke="#171B25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="Vector 4"
                d="M21 14.5L22 14.5L22 10.5L21 10.5"
                stroke="#171B25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>
