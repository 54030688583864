<template>
    <GgmsToasts />
    <GgmsSidebarAndHeader v-if="route?.meta?.requiresAuth">
        <router-view :key="selectedAgencyAndDomain" />
    </GgmsSidebarAndHeader>
    <router-view v-else />
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import GgmsSidebarAndHeader from "@/components/GgmsSidebarAndHeader.vue"
import GgmsToasts from "@/components/GgmsToasts.vue"
import { useAgencyStore } from "@/stores/agency"
import { useAgencyThemeStore } from "@/stores/agency-theme"
import { setColorShades } from "@/shared/utils/color-helpers"
import { ToastService } from "@/shared/services/toast"

const route = useRoute()
const router = useRouter()
const agencyStore = useAgencyStore()
const agencyThemeStore = useAgencyThemeStore()
const toastService = new ToastService()

const selectedAgencyAndDomain = computed(() => agencyStore.selectedAgencyAndDomain)

const storedPrimaryColor = localStorage.getItem("--primary-color")
const storedLinkColor = localStorage.getItem("--link-color")

if (storedPrimaryColor && storedPrimaryColor !== "{}") {
    setColorShades("--primary-color", storedPrimaryColor)
}

if (storedLinkColor && storedLinkColor !== "{}") {
    setColorShades("--link-color", storedLinkColor)
}

function handleRedirectErrorMessage() {
    const redirectErrorMessage = route.query as { errorMessage?: string }

    if (!redirectErrorMessage?.errorMessage) {
        return
    }
    toastService.addToast({
        type: "error",
        message: redirectErrorMessage?.errorMessage,
    })

    // After showing the error message, remove it from the url
    router.replace({ query: {} })
}

onMounted(async () => {
    await agencyThemeStore.getAgencyThemeBySubdomain()
    handleRedirectErrorMessage()
})
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

#app {
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
    display: none;
}
</style>
