<template>
    <CreateTaskForm
        @update:task="taskUpdated"
        :isForWorkflow="true"
        :modalAction="'someAction'"
        :taskId="'someTaskId'"
        :isNotEditableByUser="true"
        :errorValue="error"
    ></CreateTaskForm>
</template>

<script setup lang="ts">
import { ref } from "vue"
import CreateTaskForm from "@/components/tasks/CreateTaskForm.vue"
import { Task } from "@/shared/models/task"
import { useWorkflowStore } from "@/stores/workflow"
import { deleteEmptyKeys } from "@/shared/utils/helpers"
import { ToastService } from "@/shared/services/toast"

const workflowStore = useWorkflowStore()
const toastService = new ToastService()

const error = ref<{ field: string; message: string } | undefined>(undefined)

const taskUpdated = async (task: Task) => {
    try {
        workflowStore.areButtonDisabled = true
        error.value = undefined
        if (!task.displayName) {
            error.value = {
                field: "displayname",
                message: "Task name is required.",
            }
            return
        }
        if (!task.type) {
            error.value = {
                field: "type",
                message: "Task type is required.",
            }
            return
        }
        if (!task.when || !task.when.startAt) {
            error.value = {
                field: "queueDate",
                message: "Due date is required.",
            }
            return
        }
        if (!task.description) {
            error.value = {
                field: "description",
                message: "Task description is required.",
            }
            return
        }

        const nonEmptyObj = deleteEmptyKeys(task)
        try {
            workflowStore.modelValue = nonEmptyObj
        } catch (error) {
            toastService.addToast({
                message: "Task update error",
                type: "error",
            })
        }

        workflowStore.areButtonDisabled = false
        error.value = undefined
        if (!task.domainId) {
            error.value = {
                field: "domain",
                message: "Domain is required. Please select a domain.",
            }
            return
        }
    } catch (err: unknown) {
        error.value = { message: "Task update error", field: "" }
    }
}
</script>
