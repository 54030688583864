<template>
    <div class="flex flex-col gap-y-6">
        <div
            v-for="(branch, index) in workflowStore.branches"
            :key="branch.id"
            class="flex flex-col rounded-lg border border-gray-200 shadow-sm py-3 px-4 gap-y-3"
        >
            <p class="text-base leading-6 font-medium text-black">{{ branch.value }}</p>
            <AttributeList
                v-if="isCurrentFilterStep(branch.currentStep, 'attributeList')"
                class="mt-6"
                @onAttributeSelect="selectAttribute($event, branch.id as string)"
            />
            <FilterRule
                v-if="isCurrentFilterStep(branch.currentStep, 'filterRule')"
                class="mt-6"
                :filter="branch.selectedFilter!"
                :isUpdated="isEditFilter"
                @applyFilter="applyFilter($event, branch.id as string)"
                @cancel="cancelFilter(branch.id as string)"
            />
            <FilterList
                v-if="isCurrentFilterStep(branch.currentStep, 'filterList')"
                :savedFiltersGroups="branch.filtersGroups"
                :isWorkflow="true"
                :branchIndex="index"
                @addNewFilter="openAttributeList(branch.id as string)"
                @editFilter="editFilter($event.filter, $event.filterIndex, branch.id as string)"
                @clearFilters="clearBranchFilters(branch.id)"
            />
            <GgmsButton v-if="!branch.currentStep" class="w-fit" @click="openAttributeList(branch.id as string)"
                >Add filters <PlusIcon class="ml-2 w-4 h-4"></PlusIcon
            ></GgmsButton>
        </div>
        <GgmsButton @click="addBranch">Add Branch <PlusIcon class="ml-2 w-4 h-4"></PlusIcon></GgmsButton>
        <hr class="bg-gray-200 w-[445px] -mx-6" />
        <p class="text-base leading-6 font-medium text-black">If none of the criteria are met, go here:</p>
        <GgmsInput
            v-model="workflowStore.noneBranch.value"
            label="Branch name"
            placeholder="Conditions not met"
        ></GgmsInput>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import FilterList from "@/components/filters/FilterList.vue"
import AttributeList from "@/components/filters/AttributeList.vue"
import FilterRule from "@/components/filters/FilterRule.vue"
import { PlusIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { Attribute } from "@/shared/models/attributes"
import { Filter } from "@/shared/models/filter"
import { nanoid } from "nanoid"

const workflowStore = useWorkflowStore()
const gridCollectionStore = useGridCollectionStore()

const isEditFilter = ref(false)

function isCurrentFilterStep(currentStep: string | undefined, step: string) {
    return currentStep === step
}

function openAttributeList(branchId: string) {
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    workflowStore.branches[branchIndex].currentStep = "attributeList"
}

function openFiltersList(branchId: string) {
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    workflowStore.branches[branchIndex].currentStep = "filterList"
}
function selectAttribute(attribute: Attribute, branchId: string) {
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    workflowStore.branches[branchIndex].currentStep = "filterRule"
    if (!workflowStore.branches[branchIndex].selectedFilter) {
        return
    }
    workflowStore.branches[branchIndex].selectedFilter = {
        ...workflowStore.branches[branchIndex].selectedFilter,
        field: attribute.name as string,
        type: attribute.type as string,
        displayName: attribute.displayName as string,
    }

    isEditFilter.value = false
}

function clearBranchFilters(branchId?: string) {
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    workflowStore.branches.splice(branchIndex, 1)
    workflowStore.branches = workflowStore.branches.map((branch, index) => ({
        ...branch,
        value: `Branch ${index + 1}`,
    }))
    workflowStore.areButtonDisabled = true
}

function addFilterToGroup(filter: Filter, branchId?: string) {
    // TODO - we need to discuss about object type
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)

    if (!branch?.filtersGroups?.at(branch.selectedFilterGroup!)) {
        workflowStore.branches.at(branchIndex)?.filtersGroups?.push({ filters: [filter] })
        return
    }
    if (branchIndex) {
        workflowStore.branches.at(branchIndex)?.filtersGroups?.at(branch.selectedFilterGroup!)?.filters.push(filter)
    }
}

function editFilterGroup(filter: Filter, branchId?: string) {
    if (filter.field === "phones") {
        filter.field = "phones.number"
    } else if (filter.field === "emails") {
        filter.field = "emails.emailAddress"
    }
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    if (!branchIndex) {
        return
    }

    workflowStore.branches[branchIndex].filtersGroups[branch.selectedFilterGroup!].filters[
        branch.selectedFilterIndex!
    ] = filter
}

function applyFilter(filter: Filter, branchId: string) {
    isEditFilter.value ? editFilterGroup(filter, branchId) : addFilterToGroup(filter, branchId)
    openFiltersList(branchId)

    workflowStore.areButtonDisabled = false
}
function cancelFilter(branchId: string) {
    resetSelectedFilter(branchId)
    openFiltersList(branchId)
}
function resetSelectedFilter(branchId: string) {
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    workflowStore.branches[branchIndex].selectedFilter = { field: "", type: "", displayName: "" }
}

function editFilter(filter: Filter, filterIndex: number, branchId: string) {
    isEditFilter.value = true
    const branch = workflowStore.branches.find((branch) => branch.id === branchId)
    if (!branch) {
        return
    }
    const branchIndex = workflowStore.branches.indexOf(branch)
    if (!branchIndex) {
        return
    }
    workflowStore.branches[branchIndex].selectedFilter = {
        field: filter.field,
        type: filter.type as string,
        value: filter.value as string,
        rule: filter.rule,
        displayName: filter.displayName as string,
    }
    workflowStore.branches[branchIndex].currentStep = "filterRule"
    workflowStore.branches[branchIndex].selectedFilterIndex = filterIndex
}

function addBranch() {
    workflowStore.branches.push({
        id: nanoid(),
        value: `Branch ${workflowStore.branches.length + 1}`,
        filtersGroups: [],
        currentStep: undefined,
        selectedFilterGroup: 0,
        selectedFilterIndex: 0,
        selectedFilter: {
            field: "",
            type: "",
            rule: "",
            value: "",
            displayName: "",
        },
    })
    if (workflowStore.branches[0].filtersGroups?.length) {
        workflowStore.areButtonDisabled = false
    }
}

watch(
    () => workflowStore.noneBranch?.value,
    () => (workflowStore.areButtonDisabled = false)
)

onMounted(() => {
    gridCollectionStore.getPrototypesByPage("leads")
})
</script>
