<template>
    <svg width="380" height="83" viewBox="0 0 380 83" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41.8379 5.50195V77.8711"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.9318 16.2L67.1146 67.3828"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.12891 42.2109H77.6029"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.9318 68.117L67.1146 16.9342"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M140.952 5.50195V77.8711"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M115.046 16.2L166.229 67.3828"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M104.243 42.2109H176.717"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M115.046 68.117L166.229 16.9342"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M240.066 5.50195V77.8711"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M214.16 16.2L265.343 67.3828"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M203.357 42.2109H275.831"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M214.16 68.117L265.343 16.9342"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M339.181 5.50195V77.8711"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M313.275 16.2L364.457 67.3828"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M302.472 42.2109H374.946"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M313.275 68.117L364.457 16.9342"
            stroke="black"
            stroke-width="10"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
