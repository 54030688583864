import Crud from "./crud"
import { TimelineItem } from "@/shared/models/timelineItem"
import { Client } from "@/shared/services/client"

const client = new Client()

export class TimelineService extends Crud<TimelineItem> {
    constructor() {
        super("crm/timeline")
    }

    getTimeline(
        leadId: string,
        afterId: string | undefined,
        length: number,
        start: number | undefined,
        type: string | undefined
    ): Promise<{ data: TimelineItem[]; total: number }> {
        return client.get(this.endpoint, {
            params: {
                leadId,
                length,
                start,
                afterId,
                type,
            },
        })
    }

    pinTimelineItem(id: string, isPinned: boolean): Promise<{ timeLineItem: TimelineItem }> {
        return client.patch(`${this.endpoint}/${id}`, {
            pinned: isPinned,
        })
    }
}
