<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.58268 16.0833C5.4299 15.9306 5.35352 15.7361 5.35352 15.5C5.35352 15.2639 5.4299 15.0694 5.58268 14.9167L9.16602 11.3333V5.72917L7.60352 7.29167C7.45074 7.44444 7.2599 7.52083 7.03102 7.52083C6.80157 7.52083 6.60352 7.4375 6.43685 7.27083C6.27018 7.10417 6.18685 6.90611 6.18685 6.67667C6.18685 6.44778 6.27018 6.25 6.43685 6.08333L9.41602 3.10417C9.49935 3.02083 9.58963 2.96167 9.68685 2.92667C9.78407 2.89222 9.88824 2.875 9.99935 2.875C10.1105 2.875 10.2146 2.89222 10.3118 2.92667C10.4091 2.96167 10.4993 3.02083 10.5827 3.10417L13.6035 6.125C13.7563 6.27778 13.8363 6.46861 13.8435 6.6975C13.8502 6.92694 13.7702 7.125 13.6035 7.29167C13.4368 7.45833 13.2391 7.54167 13.0102 7.54167C12.7807 7.54167 12.5827 7.45833 12.416 7.29167L10.8327 5.72917V12L6.72852 16.1042C6.57574 16.2569 6.38824 16.3333 6.16602 16.3333C5.94379 16.3333 5.74935 16.25 5.58268 16.0833ZM13.2493 16.1042L11.166 14.0417L12.3535 12.8542L14.4368 14.9583C14.5896 15.1111 14.666 15.2986 14.666 15.5208C14.666 15.7431 14.5827 15.9375 14.416 16.1042C14.2632 16.2569 14.0724 16.3333 13.8435 16.3333C13.6141 16.3333 13.416 16.2569 13.2493 16.1042Z"
        />
    </svg>
</template>
<script setup lang="ts"></script>
