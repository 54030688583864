import Crud from "./crud"
import { Grid, GridResponse } from "@/shared/models/grid"
import { Client } from "@/shared/services/client"
import { PagedResponse } from "@/shared/models/paged-response"

const client = new Client()

export class GridService extends Crud<Grid> {
    constructor() {
        super("crm")
    }

    getGrids(type: string, typeName?: string): Promise<PagedResponse<Grid>> {
        const link = typeName ? `${this.endpoint}/${type}/grids?typeName=${typeName}` : `${this.endpoint}/${type}/grids`
        return client.get(link)
    }

    getGrid(type: string): Promise<PagedResponse<Grid>> {
        return client.get(`${this.endpoint}/${type}/grids`)
    }

    updateGrid(type: string, newGrid: Grid): Promise<GridResponse> {
        return client.patch(`${this.endpoint}/${type}/grids/${newGrid._id}`, {
            displayName: newGrid.displayName,
            columns: newGrid.columns,
            filtersGroups: newGrid.filtersGroups,
        })
    }
}
