<template>
    <Sidebar v-model:visible="isNotificationSidebarVisible" position="right" autoZIndex :showCloseIcon="false">
        <template #header
            ><div class="flex items-center justify-between w-full">
                <p class="text-white text-lg font-medium">Notifications</p>
                <XIcon class="h-6 w-6 text-white cursor-pointer" @click="closeDialog" />
            </div>
        </template>
        <div v-if="jobsArray.length" class="flex flex-col gap-4 mt-6">
            <JobItem
                v-for="[key, job] in jobsArray"
                :key="key"
                :jobTitle="job.title"
                :jobDescription="job.description"
                :jobProgress="job.progress ? job.progress : 0"
            />
        </div>
        <div v-if="taskReminders.length" class="flex flex-col gap-4 mt-6">
            <div
                class="flex flex-col px-5 py-4 gap-3 rounded-xl bg-gray-100"
                v-for="[key, task] in taskReminders"
                :key="key"
            >
                <span class="text-gray-900 text-sm font-medium"
                    >Reminder: <b>{{ task.title }}</b></span
                >
                <p class="text-gray-500 text-xs">{{ task.description }}</p>
            </div>
        </div>
        <div v-else class="text-center">You have no notifications yet.</div>
    </Sidebar>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import JobItem from "@/components/jobs/JobItem.vue"
import { XIcon } from "@heroicons/vue/outline"
import { useSocketStore } from "@/stores/socket"

const socketStore = useSocketStore()

interface Props {
    modelValue: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(["update:modelValue"])

const isNotificationSidebarVisible = computed({
    get: () => props.modelValue,
    set: (value: boolean) => {
        emit("update:modelValue", value)
    },
})

const jobsArray = computed(() => Object.entries(socketStore.inProgressJobs))
const taskReminders = computed(() => Object.entries(socketStore.taskReminders))

function closeDialog() {
    isNotificationSidebarVisible.value = false
}
</script>
