<template>
    <div class="flex w-full">
        <div class="w-full bg-white">
            <div
                class="flex w-full items-center justify-between p-2 mb-1 bg-white text-left text-base font-medium cursor-pointer hover:bg-gray-50"
                @click="toggleDisclosure"
            >
                <div class="flex w-full">
                    <slot name="header" :isOpen="isOpen" :close="closeDisclosure" />
                </div>
                <CaretIcon
                    class="h-5 w-5 select-none cursor-pointer"
                    :fill="chevronIconFillClass"
                    :class="chevronIconClass"
                />
            </div>
            <div v-if="isOpen" class="space-y-1">
                <slot name="content" :isOpen="isOpen" :close="closeDisclosure" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"
import CaretIcon from "@/components/icons/CaretIcon.vue"
import { NavigationChildItem } from "@/shared/models/navigation"
import { useRoute } from "vue-router"

interface Props {
    childrenItems: NavigationChildItem[]
}

const props = defineProps<Props>()

const route = useRoute()

const isOpen = ref(false)
const chevronIconClass = computed(() =>
    isOpen.value
        ? "rotate-90 transform stroke-primary-color-500 fill-primary-color-500"
        : "stroke-gray-300 fill-gray-300"
)

const chevronIconFillClass = computed(() => (isOpen.value ? "fill-primary-color-500" : "fill-gray-300"))

function toggleDisclosure() {
    isOpen.value = !isOpen.value
}

function checkIfDisclosureOpened() {
    if (props.childrenItems.some(({ path }) => path === route.path)) {
        toggleDisclosure()
    }
}

function closeDisclosure() {
    isOpen.value = false
}

onMounted(() => {
    checkIfDisclosureOpened()
})

defineExpose({
    closeDisclosure,
})
</script>

<style scoped>
.gray-icon {
    filter: invert(93%) sepia(4%) saturate(297%) hue-rotate(177deg) brightness(92%) contrast(92%);
}

.primary-color-icon {
    filter: invert(47%) sepia(29%) saturate(4302%) hue-rotate(163deg) brightness(85%) contrast(101%);
}
</style>
