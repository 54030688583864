<template>
    <svg width="24" height="24" viewBox="0 0 24 24" :class="props.class" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L14 10L6 14V6Z" :fill="fill" />
    </svg>
</template>

<script lang="ts" setup>
import { withDefaults } from "vue"

interface Props {
    class?: string
    fill?: string
}

const props = withDefaults(defineProps<Props>(), {
    class: "",
    fill: "none",
})
</script>
