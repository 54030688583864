<template>
    <span :class="['text-gray-500', disabledAgentStyle]">{{ currentAgent?.fullName }}</span>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useAgentsStore } from "@/stores/agents"
import { Agent } from "@/shared/models/agent"

const agentStore = useAgentsStore()

interface Props {
    agent?: Partial<Agent>
}

const props = defineProps<Props>()

const currentAgent = computed(
    () => agentStore.tableState?.data?.find((agent) => agent._id === props.agent?._id) || props.agent
)
const disabledAgentStyle = computed(() => (currentAgent.value?.status === "disabled" ? "line-through" : ""))
</script>
