<template>
    <div class="relative">
        <button
            ref="button"
            :disabled="props.disabled"
            :type="props.type"
            :class="[
                buttonDisabledClass,
                buttonIconStartClass,
                buttonIconEndClass,
                buttonPrimaryClass,
                buttonActiveClass,
                buttonSecondaryClass,
                buttonDangerClass,
                buttonUnderlinedClass,
                buttonSimpleClass,
                buttonSmallClass,
                cn(
                    'w-full flex justify-center items-center py-2 px-4 border rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
                    classes[0]
                ),
            ]"
        >
            <div v-if="props.iconStart" :class="iconStartWrapperClasses">
                <img v-if="isString(props.iconStart)" :src="props.iconStart" alt="icon" />
                <component v-else :is="props.iconStart" :class="[iconClass, ...props.iconClasses, 'h-5 w-5']" />
            </div>
            <!-- we need this when this component is used as a <component> tag -->
            <span v-if="buttonText">{{ buttonText }}</span>
            <slot v-else />
            <div v-if="props.iconEnd" @click="clickEndIcon" :class="iconEndWrapperClasses">
                <component :is="props.iconEnd" :class="[iconClass, ...props.iconClasses, 'h-5 w-5']" />
            </div>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { Component, withDefaults, computed, ref, defineExpose } from "vue"
import { isString, cn } from "@/shared/utils/helpers"

const emits = defineEmits(["clickIconStart", "clickIconEnd"])

const button = ref<HTMLElement>()

interface Props {
    type?: "button" | "submit" | "reset"
    disabled?: boolean
    emitIconsClick?: boolean
    styleType?: "primary" | "secondary" | "danger" | "active" | "underlined" | "simple"
    iconStart?: Component
    iconEnd?: Component
    classes?: string[]
    iconClasses?: string[]
    small?: boolean
    buttonText?: string
    isIconOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isIconOnly: false,
    type: "button",
    disabled: false,
    styleType: "primary",
    emitIconsClick: false,
    small: true,
    classes: () => [],
    iconClasses: () => [],
})

function clickEndIcon(event: Event) {
    event.stopPropagation()
    emits("clickIconEnd")
}

const iconClass = computed(() =>
    props.styleType === "primary"
        ? "text-white"
        : props.styleType === "active"
        ? "text-primary-color-600"
        : "text-gray-400"
)

const buttonSmallClass = computed(() => (props.small ? "h-[38px] text-sm" : "text-base"))
const buttonDisabledClass = computed(() => (props.disabled ? "opacity-40 cursor-not-allowed" : ""))
const buttonIconStartClass = computed(() =>
    props.iconStart && !props.isIconOnly ? "pl-10" : props.isIconOnly ? "pl-5" : ""
)
const buttonIconEndClass = computed(() =>
    props.iconEnd && !props.isIconOnly ? "pr-10" : props.isIconOnly ? "pr-5" : ""
)
const buttonPrimaryClass = computed(() =>
    props.styleType === "primary"
        ? "text-white bg-primary-color-600 hover:bg-primary-color-700 focus:ring-primary-color-500"
        : ""
)
const buttonActiveClass = computed(() => (props.styleType === "active" ? "text-primary-color-600" : ""))
const buttonSecondaryClass = computed(() =>
    props.styleType === "secondary"
        ? "text-gray-700 bg-white hover:bg-gray-200 focus:ring-gray-300 border-1 border-gray-300"
        : ""
)
const buttonDangerClass = computed(() =>
    props.styleType === "danger" ? "text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 border-red-600" : ""
)

const iconEndWrapperClasses = computed(() => [
    props.emitIconsClick ? "" : "pointer-events-none",
    props.small ? "h-[38px]" : "",
    props.isIconOnly ? "flex items-center justify-center" : "absolute inset-y-0 right-0 pr-3 flex items-center",
])

const iconStartWrapperClasses = computed(() => [
    props.emitIconsClick ? "" : "pointer-events-none",
    props.small ? "h-[38px]" : "",
    props.isIconOnly ? "flex items-center justify-center" : "absolute inset-y-0 left-0 pl-3 flex items-center",
])

const buttonUnderlinedClass = computed(() =>
    props.styleType === "underlined"
        ? "bg-transparent text-gray-500 underline underline-offset-4 border-0 !shadow-none"
        : ""
)

const buttonSimpleClass = computed(() =>
    props.styleType === "simple"
        ? "bg-transparent text-gray-700 !border-0 !shadow-none focus:ring-transparent focus:!ring-offset-0"
        : ""
)

defineExpose({
    buttonEl: button,
})
</script>
