<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :class="props.class"
        :fill="fill"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5 15L17.5 12L14.5 9M9.5 9L6.5 12L9.5 15M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
            :stroke="stroke"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts" setup>
import { withDefaults } from "vue"

interface Props {
    class?: string
    fill?: string
    stroke?: string
}

const props = withDefaults(defineProps<Props>(), {
    class: "",
    fill: "none",
    stroke: "currentColor",
})
</script>
