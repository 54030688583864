import { io, Socket } from "socket.io-client"
import { getToken } from "../utils/helpers"

export class SocketClientWrapper {
    private socket: Socket | null = null
    private static instance: SocketClientWrapper | null = null

    constructor(private serverUrl: string) {
        if (SocketClientWrapper.instance) {
            throw new Error("SocketClientWrapper instance already exists. Use getInstance() to obtain the instance.")
        }

        this.socket = io(serverUrl, {
            extraHeaders: {
                Authorization: `Bearer ${getToken()}`,
            },
        })
        SocketClientWrapper.instance = this
    }

    static getInstance(serverUrl: string): SocketClientWrapper {
        if (!SocketClientWrapper.instance) {
            SocketClientWrapper.instance = new SocketClientWrapper(serverUrl)
        }
        return SocketClientWrapper.instance
    }

    connect() {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.connect()
    }

    disconnect() {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.disconnect()
        this.socket = null
        SocketClientWrapper.instance = null
    }

    on(event: string, callback: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.on(event, callback)
    }

    once(event: string, callback: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.once(event, callback)
    }

    off(event: string, callback?: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        if (callback) {
            this.socket.off(event, callback)
            return
        }

        this.socket.removeAllListeners(event)
    }

    emit(event: string, ...args: any[]) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.emit(event, ...args)
    }
}
