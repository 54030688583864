import Crud from "@/shared/services/crud"
import { Client } from "@/shared/services/client"
import { Listing } from "@/shared/models/listing"
import { Tag } from "@/shared/models/tag"

const client = new Client()

export class ListingService extends Crud<Listing> {
    constructor() {
        super("crm/listings")
    }

    bulkUpdateTagOnListing(listingIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/configs`, {
            ids: listingIds,
            all,
            updates: {
                tags: {
                    toAdd,
                    toRemove,
                },
            },
        })
    }
}
