import { FiltersGroup } from "@/shared/models/filter"
import { Inbox, InboxContact } from "@/shared/models/inbox"
import { TableQueryParams } from "@/shared/models/table-query-params"
import { Client } from "./client"
import Crud from "./crud"

const client = new Client()

export class InboxService extends Crud<Inbox> {
    constructor() {
        super("/crm/messaging/inbox")
    }

    getInbox(queryParams: TableQueryParams): Promise<{ data: Inbox[]; total: number; unreadCount: number }> {
        return client.get(this.endpoint, { params: queryParams })
    }

    markAsRead(id: string): Promise<Inbox> {
        return client.patch(`${this.endpoint}/${id}/read`, { readStatus: true })
    }

    getThread(id: string): Promise<{ thread: Inbox }> {
        return client.get(`${this.endpoint}/thread/${id}`)
    }

    autocomplete(search?: string, length?: number): Promise<InboxContact[]> {
        return client.get(`${this.endpoint}/autocomplete/email`, {
            params: {
                search,
                length,
            },
        })
    }
}
