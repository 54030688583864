<template>
    <div
        :class="[styleClasses, bgColor === '#FFFFFF' ? 'border rounded-full border-gray-300' : '']"
        :style="getColorStyle()"
    >
        <Avatar
            v-if="props.hasAvatar"
            :label="getInitials(fullName)"
            shape="circle"
            class="h-4 w-4 text-[8px] mr-1"
        ></Avatar>
        <span class="truncate">
            <slot />
        </span>
        <button
            v-if="props.isRemovable"
            type="button"
            class="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none bg-transparent"
            @click.prevent.stop="$emit('onRemove')"
        >
            <XIcon class="h-3 w-3" :class="`text-[${props.textColor}]`" aria-hidden="true" />
        </button>
    </div>
</template>
<script lang="ts" setup>
import { computed, withDefaults } from "vue"
import { XIcon } from "@heroicons/vue/solid"
import { getInitials } from "@/shared/utils/helpers"

interface Props {
    isRemovable?: boolean
    bgColor?: string
    textColor?: string
    smallPadding?: boolean
    hasAvatar?: boolean
    fullName?: string
}

const props = withDefaults(defineProps<Props>(), {
    hasAvatar: false,
    isRemovable: true,
    bgColor: "#F3F4F6",
    textColor: "#282B34",
    smallPadding: false,
})

const styleClasses = computed(() => [
    props.smallPadding ? "p-0.5 pe-2" : "py-1 px-2.5",
    "inline-flex items-center rounded-full text-xs font-medium",
])

function getColorStyle() {
    return {
        backgroundColor: props.bgColor,
        color: props.textColor,
    }
}

defineEmits(["onRemove"])
</script>
