<template>
    <div :class="style">
        <slot></slot>
        <span class="text-center text-sm leading-5 font-semibold">{{ title }}</span>
    </div>
</template>
<script lang="ts" setup>
import { computed } from "vue"

interface Props {
    title: string
    disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    disabled: false,
})

const style = computed(() => [
    props.disabled ? "text-gray-400 cursor-not-allowed" : "cursor-pointer text-gray-900",
    "w-[98px] h-[94px] border border-gray-200 rounded-lg shadow-sm flex flex-col gap-y-2.5 px-4 items-center justify-center",
])
</script>
