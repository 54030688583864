import { defineStore } from "pinia"
import { TableState } from "@/shared/models/table"
import { ToastService } from "@/shared/services/toast"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { Invoice } from "../shared/models/invoice"
import { InvoiceService } from "../shared/services/invoice"

export const useInvoiceStore = defineStore("invoice", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            firstIds: [],
        } as TableState<Invoice>,
        invoiceService: new InvoiceService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
    }),
    actions: {
        async getInvoices() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.invoiceService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateInvoiceGrid()
        },

        async getInvoiceGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("invoice")
                await this.getInvoices()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateInvoiceGrid() {
            if (!this.gridCollectionStore.grid) return
            this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []
            const response = await this.gridCollectionStore.updateGrid("invoice", this.gridCollectionStore.grid)
            this.gridCollectionStore.gridResponse = response
            this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
        },
    },
})
