import { getActivePinia } from "pinia"
import { ExtendedPinia } from "@/shared/models/pinia"
import { capitalize } from "@/shared/utils/helpers"

export function useMainStore() {
    const pinia = getActivePinia() as ExtendedPinia

    if (!pinia) {
        throw new Error("There is no active Pinia instance")
    }

    const resetStores: Record<string, () => void> = {}

    pinia._s.forEach((store, name) => {
        // we gonna have lot of reset functions for each store, ex. resetAuth(), resetTask(), resetAgency(), etc.
        resetStores[`reset${capitalize(name)}`] = () => store.$reset()
    })

    resetStores.resetAll = () => pinia._s.forEach((store) => store.$reset())

    return resetStores
}
