import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { onboardingRoutes } from "@/router/onboarding"
import { peopleRoutes } from "@/router/people"
import { getToken, getUser, clearStorage, hasUserPermission } from "@/shared/utils/helpers"
import { ResourceTypeName } from "@/shared/models/role"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        redirect: () => {
            if (getToken()) return { name: "people" }

            return { name: "login" }
        },
    },
    ...onboardingRoutes,
    ...peopleRoutes,
    {
        path: "/:pathMatch(.*)",
        redirect: () => {
            if (getToken()) return { name: "people" }

            return { name: "login" }
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to) => {
    const token = getToken()
    const user = getUser()

    const toName = to.name as string

    if (toName === "login" && token && user) {
        return { name: "home" }
    }

    if (to.meta.requiresAuth && (!token || !user)) {
        clearStorage()
        return { name: "login", query: { redirect: encodeURIComponent(window.location.href) } }
    }

    if (!user?.role) {
        return
    }

    const hasPermission = hasUserPermission(user?.role, toName as ResourceTypeName)
    if (!hasPermission || (toName === "team" && user?.role?.name === "member")) {
        return { name: "home" }
    }
})

export default router
