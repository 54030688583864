import { ToastModel } from "@/shared/models/toast-model"
import { ref } from "vue"

const toasts = ref<ToastModel[]>([])

function countDown(toast: ToastModel) {
    if (toast.timer?.value > 0) {
        setTimeout(() => {
            if (toast.timer) {
                toast.timer.value -= 1
            }
            countDown(toast)
        }, 1000)
    } else {
        setTimeout(() => {
            toasts.value = toasts.value.filter((toast) => {
                return toast.timer?.value === 0 || (toast.persistent && toast.percentage !== 100)
            })
        }, 1000)
    }
}

export class ToastService {
    getToasts() {
        return toasts.value
    }

    addToast(toast: ToastModel) {
        toasts.value.push(toast)
        if (toast.persistent) {
            return
        }
        toast.timer = ref(5)
        countDown(toast)
    }

    removeToast(index: number) {
        toasts.value.splice(index, 1)
    }

    updateToast(id: string, toast: ToastModel) {
        const toastIndex = toasts.value.findIndex((toast) => toast.id === id)
        if (toastIndex === -1) {
            return
        }
        toasts.value[toastIndex] = toast
        if (toast.percentage === 100) {
            toast.timer = ref(5)
            countDown(toast)
        }
    }
}
