<template>
    <CascadeSelect
        class="p-0"
        :placeholder="placeholder"
        :options="options"
        option-label="name"
        option-group-label="name"
        :option-group-children="['children']"
        @change="onChange"
    ></CascadeSelect>
</template>

<script setup lang="ts">
import { CascadeOption } from "@/shared/models/cascade-option"

interface Props {
    options: CascadeOption[]
    placeholder: string
}

defineProps<Props>()
const emit = defineEmits(["select"])

function onChange(event: any) {
    emit("select", event.value)
}
</script>
