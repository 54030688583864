<template>
    <div
        class="max-w-sm w-full p-4 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        :class="getAttributes.toastBackground"
        v-show="toast.timer || toast.persistent"
    >
        <div class="flex">
            <div class="flex-shrink-0">
                <component
                    :is="getAttributes.icon"
                    class="h-5 w-5"
                    :class="getAttributes.iconColor"
                    aria-hidden="true"
                ></component>
            </div>
            <div class="flex flex-col w-full gap-3 ml-3">
                <p class="text-sm font-medium" :class="[getAttributes.text800]">
                    {{ toast.message }}
                </p>
                <p v-if="toast.description" class="text-sm font-medium" :class="[getAttributes.text500]">
                    {{ toast.description }}
                </p>
                <div v-if="toast.percentage !== null && toast.percentage !== undefined" class="w-full">
                    <GgmsProgressBar
                        class="flex-1"
                        :isProgressNumberVisible="true"
                        :progress="toast.percentage"
                        progressSuffix="%"
                        bar-color="bg-white"
                        progressNumberColor="text-white"
                    />
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button
                        @click="$emit('clickClose')"
                        type="button"
                        class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        :class="[
                            getAttributes.toastBackground,
                            getAttributes.text500,
                            getAttributes.hoverBackGround100,
                            getAttributes.focusRingOffset50,
                            getAttributes.focusRing50,
                        ]"
                    >
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import GgmsProgressBar from "@/components/GgmsProgressBar.vue"
import { CheckCircleIcon, ExclamationIcon, XCircleIcon, XIcon, InformationCircleIcon } from "@heroicons/vue/solid"
import { ToastModel } from "@/shared/models/toast-model"

interface Props {
    toast: ToastModel
}

const props = defineProps<Props>()

defineEmits(["clickClose"])

const getAttributes = computed(() => {
    if (props.toast.type === "success")
        return {
            toastBackground: "bg-green-50",
            text500: "text-green-500",
            text800: "text-green-800",
            hoverBackGround100: "hover:bg-green-100",
            focusRingOffset50: "focus:ring-offset-green-50",
            focusRing50: "focus:ring-green-600",
            icon: CheckCircleIcon,
            iconColor: "text-green-400",
        }

    if (props.toast.type === "error")
        return {
            toastBackground: "bg-red-50",
            text500: "text-red-500",
            text800: "text-red-800",
            hoverBackGround100: "hover:bg-red-100",
            focusRingOffset50: "focus:ring-offset-red-50",
            focusRing50: "focus:ring-red-600",
            icon: XCircleIcon,
            iconColor: "text-red-400",
        }

    if (props.toast.type === "warning")
        return {
            toastBackground: "bg-yellow-50",
            text500: "text-yellow-500",
            text800: "text-yellow-800",
            hoverBackGround100: "hover:bg-yellow-100",
            focusRingOffset50: "focus:ring-offset-yellow-50",
            focusRing50: "focus:ring-yellow-600",
            icon: ExclamationIcon,
            iconColor: "text-yellow-400",
        }

    if (props.toast.type === "info")
        return {
            toastBackground: "bg-primary-color-50",
            text500: "text-primary-color-600",
            text800: "text-primary-color-800",
            hoverBackGround100: "hover:bg-primary-color-100",
            focusRingOffset50: "focus:ring-offset-primary-color-50",
            focusRing50: "focus:ring-primary-color-600",
            icon: InformationCircleIcon,
            iconColor: "text-primary-color-600",
        }
    if (props.toast.type === "notification") {
        return {
            toastBackground: "bg-primary-color-500",
            text500: "text-gray-300",
            text800: "text-white",
            hoverBackGround100: "hover:bg-primary-color-100",
            focusRingOffset50: "focus:ring-offset-primary-color-50",
            focusRing50: "focus:ring-primary-color-600",
        }
    }
    return {}
})
</script>
