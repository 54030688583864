<template>
    <div class="relative">
        <GgmsInput v-model="searchTerm" :iconStart="SearchIcon" placeholder="Search"></GgmsInput>
        <div class="relative min-h-[288px] mt-3">
            <div class="border-gray-300 border rounded-md text-sm overflow-none">
                <div class="flex flex-col h-72 w-full overflow-y-auto py-4 px-3">
                    <div
                        class="flex flex-col w-full border-b border-b-1 last:border-0 py-2 px-1 first:pt-0 cursor-pointer hover:bg-gray-50 hover:ring-1 hover:ring-gray-400 hover:rounded-md"
                        v-for="option in options"
                        :key="option._id"
                        @click="model = option"
                    >
                        <span class="text-black font-semibold text-sm">{{ getMessageTemplateName(option) }}</span>
                        <div v-html="option.body" class="text-gray-500 text-sm font-normal truncate mt-0.5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { SearchIcon } from "@heroicons/vue/solid"
import { computed, ref } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { Template, TemplateCategory } from "@/shared/models/template"
import { useTemplateStore } from "@/stores/template"
import { useMessageStore } from "@/stores/message"

const props = defineProps<{ modelValue?: Template; category: TemplateCategory }>()
const emit = defineEmits(["update:modelValue"])

const templateStore = useTemplateStore()
const messageStore = useMessageStore()

const searchTerm = ref("")
const model = computed<Template>({
    get() {
        return props.modelValue as Template
    },
    set(value: Template) {
        emit("update:modelValue", value)
    },
})
const optionsFrom = computed<Template[]>(() =>
    messageStore.config.templates?.length
        ? messageStore.config.templates.filter((template) => template.templateType === "sms")
        : templateStore.tableState.data.filter((template) => template.templateType === props.modelValue?.templateType)
)
const options = computed<Template[]>(() => {
    return optionsFrom.value.filter((template: Template) => {
        return (
            template.subject?.toLocaleLowerCase()?.includes(searchTerm.value?.toLocaleLowerCase() ?? "") ||
            template.body?.toLocaleLowerCase()?.includes(searchTerm.value?.toLocaleLowerCase() ?? "") ||
            template.displayName?.toLocaleLowerCase()?.includes(searchTerm.value?.toLocaleLowerCase() ?? "")
        )
    })
})

function getMessageTemplateName(template: Template) {
    return template.subject ? `${template.displayName} - ${template.subject}` : template.displayName
}
</script>
