import { defineStore } from "pinia"
import { TableState } from "@/shared/models/table"
import { BulkImportData, Job } from "@/shared/models/jobs"
import { JobService } from "@/shared/services/jobs"
import { ToastService } from "@/shared/services/toast"
import { useGridCollectionStore } from "@/stores/grid-collection"

export const useJobsStore = defineStore("jobs", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            firstIds: [],
        } as TableState<Job>,
        jobService: new JobService(),
        toastService: new ToastService(),
        job: {} as Job,
        isLoading: false,
        gridCollectionStore: useGridCollectionStore(),
        isAddJobModalOpen: false,
        jobFinishedForEntity: "",
    }),
    actions: {
        async loadJobs() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.jobService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    typeName: this.tableState.typeName,
                    lastId,
                    offset,
                    filtersGroups,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]._id as string
                }

                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.selectedData = []
                this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getJobsGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("jobs")

                await this.loadJobs()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateJobsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("jobs", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.loadJobs()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateJobsGrid()
        },

        addJob(data: Partial<Job>) {
            return this.jobService.addJob(data)
        },

        async getOne(jobId: string) {
            const response = await this.jobService.getOne(jobId)
            if (!response) return

            this.job = response.job
        },

        bulkImport(payload: BulkImportData) {
            return this.jobService.bulkImport(payload)
        },

        async searchJobs(search: string, hasPhone: undefined | boolean, limit: undefined | number) {
            try {
                this.isLoading = true
                const jobs = await this.jobService.searchJobs(search, hasPhone, limit)
                return jobs
            } finally {
                this.isLoading = false
            }
        },
    },
})
