<template>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.9747 44.0125H27.9873V59.9999H43.9747V44.0125Z" fill="#99E1F4" />
        <path d="M27.9874 27.9876H12V44.0127H27.9874V27.9876Z" fill="#99E1F4" />
        <path d="M27.9874 44.0125H12V59.9999H27.9874V44.0125Z" fill="#1A82E2" />
        <path d="M60.0001 27.9876H44.0127V44.0127H60.0001V27.9876Z" fill="#00B2E3" />
        <path d="M43.9747 12.0001H27.9873V27.9875H43.9747V12.0001Z" fill="#00B2E3" />
        <path d="M43.9747 27.9876H27.9873V43.975H43.9747V27.9876Z" fill="#009DD9" />
        <path d="M60.0001 12.0001H44.0127V27.9875H60.0001V12.0001Z" fill="#1A82E2" />
    </svg>
</template>

<script lang="ts" setup></script>
