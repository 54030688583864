<template>
    <div :ref="field" class="w-full text-sm font-medium flex flex-wrap items-center min-h-[38px]">
        <span class="mr-2 text-sm py-1 capitalize">{{ fieldName }}:</span>
        <span
            v-for="(recipient, index) in localRecipints"
            :key="recipient._id"
            class="py-1 pl-2.5 pr-2 rounded-full text-sm font-medium text-gray-800 bg-gray-100 mr-1 h-6 justify-center items-center flex my-1"
        >
            {{ getFullNameWithoutUnknown(recipient.firstName, recipient.lastName, recipient.fullName) }} &nbsp;{{
                getRecipientEmailOrPhone(recipient)
            }}
            <XIcon
                class="text-gray-400 rounded-full m-0 h-4 w-4 inline-block ml-1 cursor-pointer hover:bg-gray-200"
                @click="removeRecipient(index)"
            />
        </span>
        <div v-if="isWorkflow" class="flex flex-wrap">
            <div v-if="workflowStore.currentComponent.__name === SendEmailComponent.__name" class="flex">
                <span
                    v-for="sendType in workflowStore.emailSendingTypes"
                    :key="sendType"
                    class="py-1 pl-2.5 pr-2 rounded-full text-sm font-medium text-gray-800 bg-gray-100 mr-1 h-6 justify-center items-center flex my-1"
                >
                    {{ getSendTypeForWorkflow(sendType) }}
                </span>
            </div>
            <div v-else>
                <span
                    class="py-1 pl-2.5 pr-2 rounded-full text-sm font-medium text-gray-800 bg-gray-100 mr-1 h-6 justify-center items-center flex my-1"
                >
                    Contacts enrolled in this workflow
                </span>
            </div>
        </div>
        <ContactSelect
            v-else
            :type="type"
            :recipients="recipients"
            :toFieldLength="offsetWidth"
            :fieldName="fieldName"
            @close="closeContactSelect"
            @select="addRecipient"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import ContactSelect from "@/components/profile-details/middle-panel/ContactSelect.vue"
import { XIcon } from "@heroicons/vue/outline"
import { InboxContact } from "@/shared/models/inbox"
import { getSendTypeForWorkflow, internationalPhoneFormat, getFullNameWithoutUnknown } from "@/shared/utils/helpers"
import { useWorkflowStore } from "@/stores/workflow"
import SendEmailComponent from "@/components/workflows/sidebar/action/communications/SendEmailComponent.vue"

const workflowStore = useWorkflowStore()

interface Props {
    type: string
    fieldName: string
    recipients: InboxContact[]
    offsetWidth?: number
    isReply?: boolean
    isForward?: boolean
    from?: string
    to?: string[]
    isWorkflow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isWorkflow: false,
})

const emit = defineEmits(["addRecipient", "removeRecipient", "closeContactSelect"])

const localRecipints = computed(() => props.recipients)
const isMessageType = computed(() => props.type === "sms")
const field = computed(() => `${props.fieldName}Field`)

function getRecipientEmailOrPhone(recipient: InboxContact) {
    return isMessageType.value && recipient?.phone?.number
        ? internationalPhoneFormat(recipient?.phone?.number || "")
        : getRecipientEmail(recipient)
}

function getRecipientEmail(recipient: InboxContact) {
    if (!props.isReply || props.isForward) {
        return recipient?.email || ""
    }

    if (recipient.email !== props.from) {
        return props.to?.[0]
    }

    return props.from
}

function addRecipient(recipient: InboxContact) {
    emit("addRecipient", recipient)
}

function removeRecipient(index: number) {
    emit("removeRecipient", index)
}

function closeContactSelect() {
    emit("closeContactSelect")
}
</script>
