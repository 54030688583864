import { Call, Device } from "@twilio/voice-sdk"
import { PhoneCallState } from "@/shared/models/phone-call"

type Callback = (...args: any[]) => void

export class TwilioVoiceClient {
    private call: Call | null = null
    private isMuted = false
    private registered: { [eventName: string]: Callback[] } = {}

    addEventListener(eventName: string, callback: Callback): void {
        if (!this.registered[eventName]) {
            this.registered[eventName] = []
        }
        this.registered[eventName].push(callback)
    }

    triggerEvent(eventName: string, args?: any[]): void {
        const callbacks = this.registered[eventName]
        if (callbacks) {
            callbacks.forEach((callback) => {
                callback.apply(this, args || [])
            })
        }
    }

    removeListener(eventName: string, callback: Callback): void {
        const callbacks = this.registered[eventName]
        if (callbacks) {
            const index = callbacks.indexOf(callback)
            if (index !== -1) {
                callbacks.splice(index, 1)
            }
        }
    }

    async connect(token: string, phoneNumber: string): Promise<void> {
        try {
            const device = new Device(token, {
                logLevel: 1,
                codecPreferences: ["opus", "pcmu"] as Call.Codec[],
            })

            const params = {
                To: phoneNumber,
            }

            console.log(`Connecting to Twilio Voice SDK...`, device)

            this.call = await device.connect({
                params,
            })

            this.subscribeToTwilioEvents()

            console.log(`Connecting to participant ${phoneNumber}`, this.call)
        } catch (error) {
            console.error(`Error connecting to participant: ${error}`)
        }
    }

    disconnect() {
        if (!this.call) {
            console.warn("No active call to disconnect.")
            return
        }
        this.call.disconnect()
        this.call = null
        this.isMuted = false
    }

    toggleMute() {
        if (!this.call) {
            console.warn("No active call to mute.")
            return
        }

        this.call.mute(!this.isMuted)
        this.isMuted = !this.isMuted
        console.log(`Call ${this.isMuted ? "muted" : "unmuted"}.`)
    }

    async statusChange(status: PhoneCallState) {
        this.triggerEvent("statusChange", [status])
    }

    async createCall(twilioCallId: string | undefined) {
        this.triggerEvent("createCall", [twilioCallId])
    }

    subscribeToTwilioEvents() {
        if (!this.call) {
            return
        }

        this.call.on("accept", async () => {
            this.statusChange("ongoing")
            this.createCall(this.call?.parameters?.CallSid)
        })

        this.call.on("disconnect", () => {
            try {
                this.statusChange("finished")
                this.call = null
            } catch (error) {
                console.log(`Error disconnecting call: ${error}`)
            }
        })
    }
}

export default TwilioVoiceClient
