import { defineStore } from "pinia"
import { AuthService } from "@/shared/services/auth"
import { Invite } from "@/shared/models/invite"
import { User } from "@/shared/models/user"
import { Phone } from "@/shared/models/phone"
import { AuthUser, Social } from "@/shared/models/auth-user"

export const useAuthStore = defineStore("auth", {
    state: () => ({
        authService: new AuthService(),
        currentUser: {} as User,
    }),
    actions: {
        confirmEmail(token: string): Promise<AuthUser> {
            return this.authService.confirmEmail(token)
        },

        getInvite(token: string): Promise<Invite> {
            return this.authService.getInvite(token)
        },

        googleLogin(user: "admin" | "agent") {
            return this.authService.googleLogin(user)
        },

        register(
            email: string,
            password: string,
            agencyName: string,
            agencyPhoneNumber: Phone,
            firstName: string,
            lastName: string,
            agencyDomain: string,
            timezone: string,
            social?: Social
        ) {
            return this.authService.register(
                email,
                password,
                agencyName,
                agencyPhoneNumber,
                firstName,
                lastName,
                agencyDomain,
                timezone,
                social
            )
        },

        registerAgent(
            email: string,
            password: string,
            phoneNumber: Phone,
            firstName: string,
            lastName: string,
            token?: string,
            social?: Social
        ) {
            return this.authService.registerAgent(email, password, phoneNumber, firstName, lastName, token, social)
        },

        login(email: string, password: string, tokenRedirect?: boolean, rememberMe?: boolean) {
            return this.authService.login(email, password, tokenRedirect, rememberMe)
        },

        logout() {
            return this.authService.logout()
        },

        acceptInvite(token: string) {
            return this.authService.acceptInvite(token)
        },

        autoLogin(token: string) {
            return this.authService.autoLogin(token)
        },
    },
})
