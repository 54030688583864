import { Form, FormAnswer } from "../models/form"
import { BulkUpdateTags, Tag } from "../models/tag"
import { Client } from "./client"
import Crud from "./crud"
import { ResponsePresignedUrl, UploadItem } from "../models/upload-file"

const client = new Client()

export class FormService extends Crud<Form> {
    constructor() {
        super("crm/forms")
    }

    getForms(): Promise<Form[]> {
        return client.get(this.endpoint)
    }

    createForm(form: Form): Promise<Form> {
        return client.post(this.endpoint, form)
    }

    updateForm(id: string, form: Form): Promise<{ form: Form }> {
        return client.patch(`${this.endpoint}/${id}`, form)
    }

    bulkUpdateTags(formIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/tags`, {
            ids: formIds,
            all,
            updates: {
                toAdd,
                toRemove,
            },
        })
    }

    getFormResponses(formId: string): Promise<{ responses: FormAnswer[] }> {
        return client.get(`${this.endpoint}/${formId}/responses`)
    }

    getFormResponse(formId: string, responseId: string): Promise<{ response: FormAnswer }> {
        return client.get(`${this.endpoint}/${formId}/responses/${responseId}`)
    }

    createFormResponse(formId: string, formAnswer: FormAnswer): Promise<FormAnswer> {
        return client.post(`${this.endpoint}/responses/${formId}`, formAnswer)
    }

    requestPostDataForFileUpload(uploads: UploadItem[]): Promise<ResponsePresignedUrl> {
        return client.post(`${this.endpoint}/files`, { uploads })
    }

    uploadFiles(endpoint: string, data: FormData) {
        return client.postFile(endpoint, data)
    }

    autocomplete(search?: string): Promise<Form[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
            },
        })
    }
}
