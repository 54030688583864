<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Input type">
            <rect
                id="Rectangle 22"
                x="2"
                y="7.5"
                width="20"
                height="10"
                rx="2"
                stroke="#171B25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="Vector 2"
                d="M9 12.5L15 12.5"
                stroke="#171B25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>
