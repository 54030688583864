import { BaseModel } from "@/shared/models/base-model"
import { Category } from "@/shared/models/category"

export enum AttributeType {
    String = "string",
    Number = "number",
    Boolean = "boolean",
    Object = "object",
    Dropdown = "dropdown",
    Date = "date",
    Tag = "tag",
    Emails = "email[]",
    Phones = "phonenumber[]",
    Origin = "origin[]",
    Tags = "tag[]",
}

export interface Attribute extends BaseModel {
    type: AttributeType
    isHidden?: boolean
    isMutable?: boolean
    isRequired?: boolean
    category?: Category
    displayName: string
    name: string
    isRoot?: boolean
}
