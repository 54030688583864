<template>
    <div>
        <div class="flex">
            <label v-if="label" for="comment" class="block text-sm font-medium mb-1 text-gray-700">{{ label }}</label>
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <p v-if="description" class="text-sm text-gray-500 mt-3 mb-4">
            {{ description }}
        </p>
        <div class="relative">
            <div v-show="roundedTop" class="bg-white h-2 rounded-t-md"></div>
            <textarea
                ref="textarea"
                :value="modelValue"
                :placeholder="placeholder"
                :rows="rows"
                :class="textAreaStyle"
                :readonly="readonly"
                :disabled="disabled"
                @input="onInput"
                @focus="disableValidationError"
            />
            <div
                v-if="invalid && !modelValue"
                class="absolute z-10 top-2.5 right-0 pr-3 flex items-center cursor-pointer"
            >
                <component :is="ExclamationCircleIcon" class="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
            <div v-show="roundedBottom" class="bg-white h-2 rounded-b-md"></div>
        </div>
        <p v-if="errorMessage" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
        <div v-if="isCountAvailable" class="flex w-full gap-x-4 mt-2">
            <p class="text-sm text-gray-500">
                Characters: <span class="font-medium text-gray-700">{{ modelValue.length }}</span>
            </p>
            <p class="text-sm text-gray-500">
                SMS: <span class="font-medium text-gray-700">{{ smsCount }}</span>
            </p>
            <p class="text-sm text-gray-500">
                Cost:
                <span :class="cost > remainingPoints ? 'text-orange-500' : ''" class="font-medium text-gray-700">{{
                    cost
                }}</span>
                <span class="font-medium text-gray-400">/{{ remainingPoints }}</span>
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { withDefaults, computed, onMounted, watch, ref } from "vue"
import { ExclamationCircleIcon } from "@heroicons/vue/solid"
import { useAgencyStore } from "@/stores/agency"

const emit = defineEmits(["update:modelValue", "disableValidationError", "smsCountChanged", "costExceeded"])

interface Props {
    modelValue: string
    templateString?: string
    label?: string
    description?: string
    rows?: number
    placeholder?: string
    invalid?: boolean
    errorMessage?: string
    textAreaClasses?: string[]
    readonly?: boolean
    isCountAvailable?: boolean
    numberOfRecipients?: number
    required?: boolean
    disabled?: boolean
    rounded?: boolean
    borderless?: boolean
    roundedTop?: boolean
    roundedBottom?: boolean
    styleType?: "primary" | "onlyEmail" | "onlyPhone" | "emailAndPhone"
}

const textarea = ref()

const props = withDefaults(defineProps<Props>(), {
    modelValue: "",
    templateString: "",
    label: "",
    rows: 4,
    placeholder: "",
    tedxtAreaClasses: "",
    readonly: false,
    isCountAvailable: false,
    numberOfRecipients: 1,
    required: false,
    disabled: false,
    rounded: true,
    borderless: false,
    roundedTop: false,
    roundedBottom: false,
    styleType: "primary",
})

const agencyStore = useAgencyStore()

const smsCount = computed(() => calculateNumberOfMessages(props.modelValue))

const cost = computed(() => {
    const costPerSms = 1
    return smsCount.value * costPerSms
})

const remainingPoints = computed(() => agencyStore.agency.points?.balance)

const styleTypeToHeight = {
    onlyEmail: "h-[343px]",
    onlyPhone: "h-[221px]",
    emailAndPhone: "h-[282px]",
    primary: "",
}

const textAreaStyle = computed(() => [
    props.borderless ? "border-0 focus:border-0 focus:ring-transparent" : "",
    props.disabled ? "opacity-50 cursor-not-allowed" : "",
    props.invalid && !props.modelValue?.length
        ? "pr-10 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
        : "placeholder-gray-400 focus:ring-primary-color-500 focus:border-primary-color-500 border-gray-300 ",
    props.readonly ? "opacity-50 focus:ring-transparent focus:border-gray-300 cursor-not-allowed" : "",
    props.rounded ? "rounded-md" : "",
    "shadow-sm block w-full ",
    props.textAreaClasses,
    styleTypeToHeight[props.styleType] || "",
])

const starStyle = computed(() => [props.invalid ? "text-red-600" : "text-gray-700", "pl-1"])

function onInput(event: Event) {
    if (event) {
        const target = event.target as HTMLTextAreaElement
        emit("update:modelValue", target.value)
        emit("smsCountChanged", smsCount.value)
    }
}
function calculateNumberOfMessages(textMessage: string) {
    if (!textMessage) {
        return 0
    }
    const MAX_UNICODE_CHARS_PER_MESSAGE = 70

    // Calculate the maximum length of the message based on the character set
    // eslint-disable-next-line no-control-regex
    const maxMessageLength = textMessage.match(/^[\u0000-\u007F]*$/)
        ? 160 // GSM 7-bit characters
        : MAX_UNICODE_CHARS_PER_MESSAGE // Unicode characters

    // Calculate the number of messages required to send the entire message
    if (textMessage.length > maxMessageLength) {
        // Split message into parts of 67 characters each
        return Math.ceil(textMessage.length / (maxMessageLength - 7))
    } else {
        return 1
    }
}

function disableValidationError() {
    emit("disableValidationError")
}

function onSelectInsertString(templateString: string) {
    const cursorPosition = textarea.value.selectionStart
    const textAreaValue = textarea.value.value
    const textBefore = textAreaValue.substring(0, cursorPosition)
    const textAfter = textAreaValue.substring(cursorPosition, textAreaValue.length)
    textarea.value.value = `${textBefore}${templateString}${textAfter}`
    textarea.value.focus()
    textarea.value.selectionStart = cursorPosition + templateString.length
    textarea.value.selectionEnd = cursorPosition + templateString.length
    emit("update:modelValue", textarea.value.value)
}

onMounted(() => {
    disableValidationError()
})

watch(
    () => props.templateString,
    (val) => {
        onSelectInsertString(val)
    }
)

watch(
    () => cost.value,
    () => {
        if (cost.value > remainingPoints.value) {
            emit("costExceeded", true)
        } else {
            emit("costExceeded", false)
        }
    }
)

defineExpose({
    textarea,
})
</script>
