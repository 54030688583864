import { RouteRecordRaw } from "vue-router"

export const onboardingRoutes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/onboarding/SignInView.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/onboarding/RegisterView.vue"),
    },
    {
        path: "/mail-confirmation",
        name: "mailConfirmation",
        meta: {
            auth: true,
        },
        component: () => import("@/views/onboarding/MailConfirmation.vue"),
    },
    {
        path: "/forgot",
        name: "forgot",
        component: () => import("@/views/onboarding/ForgotView.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/views/onboarding/ResetPasswordView.vue"),
    },
    {
        path: "/confirm-email",
        name: "confirmEmail",
        component: () => import("@/views/onboarding/ConfirmEmail.vue"),
    },
    {
        path: "/join",
        name: "join",
        component: () => import("@/views/onboarding/JoinView.vue"),
    },
    {
        path: "/reply",
        name: "messageReply",
        component: () => import("@/views/messageReply/MessageReplyView.vue"),
    },
    {
        path: "/public/forms/:formId",
        name: "publicForm",
        component: () => import("@/views/publicForm/PublicFormPreviewView.vue"),
    },
    {
        path: "/public/forms/:formId/response/:responseId",
        name: "publicFormResponse",
        component: () => import("@/views/publicForm/PublicFormPreviewView.vue"),
    },
    {
        path: "/public/leads/unsubscribe",
        name: "unsubscribeLead",
        component: () => import("@/views/unsubscribeLead/UnsubscribeLeadView.vue"),
    },
]
