import { BaseModel } from "./base-model"
import { Form, FormAnswer } from "./form"

export enum IntegrationType {
    bulkEmail = "bulkEmail",
    inboxEmail = "inboxEmail",
    phone = "phone",
    leadManager = "leadManager",
}

export enum IntegrationName {
    sendgrid = "sendgrid",
    gmail = "gmail",
    outlook = "outlook",
    twilio = "twilio",
    custom = "custom",
}

export interface IntegrationConfig extends BaseModel {
    formId: string
    userId: string
    versions?: FormAnswer[]
}

export interface PayloadIntegration extends BaseModel {
    integrationType: IntegrationType
    integrationName: IntegrationName
    redirectUrl?: string
    providedIntegrationId?: string
    config?: FormAnswer
}

export interface Integration extends BaseModel, PayloadIntegration {
    isEnabled: boolean
    fromAddress?: string
    fromName?: string
    remainingDailySentMessagesLimit?: number
    remainingDailySentMessagesThreshold?: number
}

export interface Provider extends BaseModel {
    description: {
        "en-US": string
    }
    logo: string
    name: string
    url: string
}

export interface BaseIntegration extends BaseModel, PayloadIntegration {
    description: {
        "en-US": string
    }
    integration: Integration
    name: string
    provider: Provider
    publisher: Provider
    version: string
    form: Form
}
