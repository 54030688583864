<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">
            Choose how long the contact should wait before moving to the next action
        </p>
        <div class="flex gap-x-2.5 pb-4">
            <GgmsInput v-model="workflowStore.modelValue.time.days" type="number" label="Days"></GgmsInput>
            <GgmsInput v-model="workflowStore.modelValue.time.hours" type="number" label="Hours"></GgmsInput>
            <GgmsInput v-model="workflowStore.modelValue.time.minutes" type="number" label="Minutes"></GgmsInput>
        </div>
        <p class="test-sm text-gray-400 font-light">1 day is equal to a 24 hour period</p>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { useWorkflowStore } from "@/stores/workflow"

const workflowStore = useWorkflowStore()

watch(
    () => [
        workflowStore.modelValue.time?.days,
        workflowStore.modelValue.time?.hours,
        workflowStore.modelValue.time?.minutes,
    ],
    () => {
        workflowStore.areButtonDisabled = !(
            workflowStore.modelValue.time?.days !== 0 ||
            workflowStore.modelValue.time?.hours !== 0 ||
            workflowStore.modelValue.time?.minutes !== 0
        )
    }
)
</script>
