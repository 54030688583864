<template>
    <div>
        <p class="text-lg leading-7 font-medium text-black">Enroll to</p>
        <div id="searchOverlayTargetToAdd">
            <GgmsInput
                v-model="toAddSearch"
                class="w-full mt-4"
                :iconStart="SearchIcon"
                placeholder="Search"
                @input="toggleSearchMenuToAdd"
                @click="toggleSearchMenuToAdd"
            ></GgmsInput>
            <OverlayPanel
                ref="searchMenuToAdd"
                class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                appendTo="#searchOverlayTargetToAdd"
            >
                <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                    <div class="relative">
                        <div v-if="!toEnrollWorkflows?.length" class="py-6 border-0 px-4">No results found</div>
                        <template v-else>
                            <div
                                v-for="workflow in toEnrollWorkflows"
                                :key="workflow._id"
                                class="flex items-center gap-x-2 px-4 py-3 cursor-pointer border-b border-gray-200 last:border-0"
                                tabindex="0"
                            >
                                <GgmsCheckBox
                                    :checked="isElementCheckedToAdd(workflow)"
                                    @click="onClickToAdd(workflow)"
                                >
                                    <div
                                        class="ml-3 min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex"
                                    >
                                        {{ workflow?.name }}
                                    </div>
                                </GgmsCheckBox>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearchingToAdd" />
                    </div>
                </div>
                <div
                    v-if="workflowStore.toAdd.length"
                    class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                >
                    <div class="flex items-center gap-x-4">
                        <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearToAddData">
                            <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                        </div>
                        <p class="text-base leading-6 font-medium w-fit">
                            {{ workflowStore.toAdd.length }} selected -
                            <span
                                class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                @click="onSelectToAdd"
                            >
                                {{ selectAllTextToAdd }}
                            </span>
                        </p>
                    </div>
                    <GgmsButton @click="closeSearchMenuToAdd">Done</GgmsButton>
                </div>
            </OverlayPanel>
            <div v-if="showDataToAdd" class="flex flex-col pt-3">
                <div
                    v-for="workflow in workflowStore.toAdd"
                    :key="workflow._id"
                    class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
                >
                    <div class="min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex">
                        {{ workflow?.name }}
                    </div>
                    <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteDataToAdd(workflow)"></TrashIcon>
                </div>
            </div>
        </div>

        <hr class="bg-gray-200 w-[445px] -mx-6 mt-5 mb-4" />

        <p class="text-lg leading-7 font-medium text-black">Unenroll from</p>
        <div id="searchOverlayTargetToRemove">
            <GgmsInput
                v-model="toRemoveSearch"
                class="w-full mt-4"
                :iconStart="SearchIcon"
                placeholder="Search"
                @input="toggleSearchMenuToRemove"
                @click="toggleSearchMenuToRemove"
            ></GgmsInput>
            <OverlayPanel
                ref="searchMenuToRemove"
                class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                appendTo="#searchOverlayTargetToRemove"
            >
                <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                    <div class="relative">
                        <div v-if="!toUnenrollWorkflows?.length" class="py-6 border-0 px-4">No results found</div>
                        <template v-else>
                            <div
                                v-for="workflow in toUnenrollWorkflows"
                                :key="workflow._id"
                                class="flex items-center gap-x-2 px-4 py-3 cursor-pointer border-b border-gray-200 last:border-0"
                                tabindex="0"
                            >
                                <GgmsCheckBox
                                    :checked="isElementCheckedToRemove(workflow)"
                                    @click="onClickToRemove(workflow)"
                                >
                                    <div
                                        class="ml-3 min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex"
                                    >
                                        {{ workflow?.name }}
                                    </div>
                                </GgmsCheckBox>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearchingToRemove" />
                    </div>
                </div>
                <div
                    v-if="workflowStore.toRemove.length"
                    class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                >
                    <div class="flex items-center gap-x-4">
                        <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearToRemoveData">
                            <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                        </div>
                        <p class="text-base leading-6 font-medium w-fit">
                            {{ workflowStore.toRemove.length }} selected -
                            <span
                                class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                @click="onSelectToRemove"
                            >
                                {{ selectAllTextToRemove }}
                            </span>
                        </p>
                    </div>
                    <GgmsButton @click="closeSearchMenuToRemove">Done</GgmsButton>
                </div>
            </OverlayPanel>
            <div v-if="showDataToRemove" class="flex flex-col pt-3">
                <div
                    v-for="workflow in workflowStore.toRemove"
                    :key="workflow._id"
                    class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
                >
                    <div class="min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex">
                        {{ workflow?.name }}
                    </div>
                    <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteDataToRemove(workflow)"></TrashIcon>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { MinusSmIcon, SearchIcon, TrashIcon } from "@heroicons/vue/outline"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { debounce, getCopyArray } from "@/shared/utils/helpers"
import { Workflow } from "@/shared/models/workflow"

const workflowStore = useWorkflowStore()

const searchMenuToAdd = ref()
const toAddSearch = ref("")
const isSearchingToAdd = ref(false)
const isAllToAddSelected = ref(false)
const totalToAdd = ref(0)
const showDataToAdd = ref(false)

const searchMenuToRemove = ref()
const toRemoveSearch = ref("")
const isSearchingToRemove = ref(false)
const isAllToRemoveSelected = ref(false)
const totalToRemove = ref(0)
const showDataToRemove = ref(false)

const toEnrollWorkflows = ref<Workflow[]>([])
const toUnenrollWorkflows = ref<Workflow[]>([])
const data = ref<Workflow[]>([])

const selectAllTextToAdd = computed(() =>
    isAllToAddSelected.value ? "Deselect all" : `Select all (${totalToAdd.value})`
)
const selectAllTextToRemove = computed(() =>
    isAllToRemoveSelected.value ? "Deselect all" : `Select all (${totalToRemove.value})`
)

async function getWorkflows() {
    if (workflowStore.workflow._id) {
        toEnrollWorkflows.value = await workflowStore.autocomplete(toAddSearch.value, workflowStore.workflow._id)
        toUnenrollWorkflows.value = await workflowStore.autocomplete(toRemoveSearch.value, workflowStore.workflow._id)
    } else {
        toEnrollWorkflows.value = await workflowStore.autocomplete(toAddSearch.value)
        toUnenrollWorkflows.value = await workflowStore.autocomplete(toRemoveSearch.value)
    }
}

function toggleSearchMenuToAdd(event?: Event) {
    searchMenuToAdd.value.show(event)
}

function toggleSearchMenuToRemove(event?: Event) {
    searchMenuToRemove.value.show(event)
}

function clearToAddData() {
    workflowStore.toAdd = []
}

function clearToRemoveData() {
    workflowStore.toRemove = []
}

function onSelectToAdd() {
    if (isAllToAddSelected.value) {
        clearToAddData()
    } else {
        workflowStore.toAdd = getCopyArray(toEnrollWorkflows.value)
    }
    isAllToAddSelected.value = !isAllToAddSelected.value
}

function onSelectToRemove() {
    if (isAllToRemoveSelected.value) {
        clearToRemoveData()
    } else {
        workflowStore.toRemove = getCopyArray(toUnenrollWorkflows.value)
    }
    isAllToRemoveSelected.value = !isAllToRemoveSelected.value
}

function closeSearchMenuToAdd() {
    showDataToAdd.value = true
    searchMenuToAdd.value.hide()
}

function closeSearchMenuToRemove() {
    showDataToRemove.value = true
    searchMenuToRemove.value.hide()
}

function isElementCheckedToAdd(element: Workflow) {
    return !!workflowStore.toAdd.find((el) => el._id === element._id)
}

function onClickToAdd(element: Workflow) {
    if (isElementCheckedToAdd(element)) {
        const index = workflowStore.toAdd.findIndex((el) => el._id === element._id)
        workflowStore.toAdd.splice(index, 1)
        return
    }

    workflowStore.toAdd.push(element)
}

function isElementCheckedToRemove(element: Workflow) {
    return !!workflowStore.toRemove.find((el) => el._id === element._id)
}

function onClickToRemove(element: Workflow) {
    if (isElementCheckedToRemove(element)) {
        const index = workflowStore.toRemove.findIndex((el) => el._id === element._id)
        workflowStore.toRemove.splice(index, 1)
        return
    }

    workflowStore.toRemove.push(element)
}
function deleteDataToAdd(element: Workflow) {
    workflowStore.toAdd.splice(workflowStore.toAdd.indexOf(element), 1)
}

function deleteDataToRemove(element: Workflow) {
    workflowStore.toRemove.splice(workflowStore.toRemove.indexOf(element), 1)
}

watch(
    () => toAddSearch.value,
    debounce(async () => {
        if (workflowStore.workflow._id) {
            toEnrollWorkflows.value = await workflowStore.autocomplete(toAddSearch.value, workflowStore.workflow._id)
        } else {
            toEnrollWorkflows.value = await workflowStore.autocomplete(toAddSearch.value)
        }
    }, 250)
)

watch(
    () => toRemoveSearch.value,
    debounce(async () => {
        if (workflowStore.workflow._id) {
            toUnenrollWorkflows.value = await workflowStore.autocomplete(
                toRemoveSearch.value,
                workflowStore.workflow._id
            )
        } else {
            toUnenrollWorkflows.value = await workflowStore.autocomplete(toRemoveSearch.value)
        }
    }, 250)
)

watch(
    () => [workflowStore.toAdd, workflowStore.toRemove],
    () => {
        workflowStore.areButtonDisabled = !workflowStore.toAdd.length && !workflowStore.toRemove.length
        if (workflowStore.toAdd.length !== toEnrollWorkflows.value.length && isAllToAddSelected.value) {
            isAllToAddSelected.value = !isAllToAddSelected.value
        }

        if (workflowStore.toRemove.length !== toUnenrollWorkflows.value.length && isAllToRemoveSelected.value) {
            isAllToRemoveSelected.value = !isAllToRemoveSelected.value
        }
    },
    { deep: true }
)

onMounted(async () => {
    await getWorkflows()
    if (workflowStore.toAdd.length) {
        showDataToAdd.value = true
    }

    if (workflowStore.toRemove.length) {
        showDataToRemove.value = true
    }
    totalToAdd.value = toEnrollWorkflows.value.length
    totalToRemove.value = toUnenrollWorkflows.value.length
})
</script>
