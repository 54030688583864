<template>
    <TransitionRoot as="template" :show="props.open">
        <Dialog as="div" class="fixed z-40 inset-0" @close="$emit('closeModal')">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-100"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="relative inline-block rounded-lg align-middle text-left shadow-xl transform transition-all sm:my-8"
                        :class="sizeClass"
                    >
                        <div :class="cn('flex justify-between bg-white rounded-t-lg w-full p-6', headerClass)">
                            <slot name="header" />
                            <XIcon
                                v-if="!props?.hideCloseButton"
                                class="h-6 w-6 self-center text-gray-400 cursor-pointer hover:text-gray-500"
                                @click="$emit('closeModal')"
                            ></XIcon>
                        </div>
                        <div
                            :class="[
                                slots.under ? 'rounded-b-none' : 'rounded-b-lg',
                                cn('bg-white px-6 pt-1.5 pb-10 overflow-y-auto max-h-[70vh] 2xl:max-h-[80]', bodyClass),
                            ]"
                        >
                            <slot></slot>
                        </div>
                        <div v-if="slots.under" class="bg-gray-50 px-6 py-3 rounded-b-lg">
                            <slot name="under" />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, useSlots } from "vue"
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue"
import { XIcon } from "@heroicons/vue/outline"
import { cn } from "@/shared/utils/helpers"

defineEmits(["closeModal"])

interface Props {
    open: boolean
    size?: "sm" | "md" | "lg" | "xl" | "3xl"
    hideCloseButton?: boolean
    bodyClass?: string
    fullWidth?: boolean
    headerClass?: string
}

const props = withDefaults(defineProps<Props>(), {
    fullWidth: true,
})
const slots = useSlots()
const sizes = ["sm:max-w-sm", "sm:max-w-xl", "sm:max-w-lg", "sm:max-w-3xl"]
const sizeClass = computed(() => {
    return [sizes.find((size) => size.includes(props.size as string)) || sizes[1], props.fullWidth ? "w-full" : ""]
})
</script>
