import { BaseModel } from "@/shared/models/base-model"
import { Phone } from "./phone"
import { UploadFile } from "./upload-file"
import { Agency } from "./agency"
import { Social } from "./auth-user"
import { Role } from "./role"

export enum AuthUserStatus {
    pending = "pending",
    active = "active",
    disabled = "disabled",
}

export enum AuthUserRole {
    owner = "owner",
    member = "member",
}

export interface User extends BaseModel {
    email: string
    agency: Agency
    status?: AuthUserStatus
    fullName?: string
    firstName?: string
    lastName?: string
    outboundPhoneNumber?: Phone
    phoneNumber?: Phone
    profilePicture?: UploadFile
    social?: Social
    emailConfirmation?: {
        code: string
        confirmed?: boolean
        checkedAt?: Date
    }
    invites?: {
        ownedById: string
        role: Role
        emailConfirmation: {
            code: string
            confirmed?: boolean
            checkedAt?: Date
        }
    }[]
    role?: Role
    voiceAsset?: UploadFile
    ownedBy?: Partial<Agency>
}
