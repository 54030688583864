<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">Time of day workflow should trigger</p>
        <GgmsInput
            v-model="workflowStore.modelValue.time"
            label="Time of day"
            type="time"
            :iconStart="ClockIcon"
        ></GgmsInput>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import { ClockIcon } from "@heroicons/vue/outline"
import GgmsInput from "@/components/GgmsInput.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter } from "@/shared/utils/helpers"

const workflowStore = useWorkflowStore()

watch(
    () => workflowStore.modelValue.time,
    () => {
        //convert time into ISO date
        if (workflowStore.modelValue.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(workflowStore.modelValue.time)
        }
        workflowStore.areButtonDisabled = !workflowStore.modelValue.time
    }
)
</script>
