import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Message } from "../models/message"
import { Config } from "../models/config"
import { Inbox } from "../models/inbox"

const client = new Client()

export class MessageService extends Crud<Message> {
    constructor() {
        super("/crm/messaging/sms")
    }

    createMessage(message: Message): Promise<{ threads: Inbox[] }> {
        return client.post(this.endpoint, message)
    }

    updateMessage(messageId: string, message: Partial<Message>): Promise<Message> {
        return client.put(`${this.endpoint}/${messageId}`, message)
    }

    cancelMessage(messageId: string): Promise<Message> {
        return client.delete(`${this.endpoint}/${messageId}`)
    }

    getMessagingReply(token: string): Promise<Config> {
        return client.get(`${this.endpoint}/reply/${token}`)
    }

    replyToMessage(body: string, token: string): Promise<Message> {
        return client.post(`${this.endpoint}/reply/${token}`, { body })
    }
}
