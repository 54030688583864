<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.14643 5.5L5.85354 6.20711L3.49999 8.56066L2.14643 7.20711L2.85354 6.5L3.49999 7.14655L5.14643 5.5ZM3.5 17.3535C2.67 17.3535 2 18.0335 2 18.8535C2 19.6735 2.68 20.3535 3.5 20.3535C4.32 20.3535 5 19.6735 5 18.8535C5 18.0335 4.33 17.3535 3.5 17.3535ZM21.5 19.8536H7.5V17.8535H21.5V19.8536ZM7.5 13.8535H21.5V11.8535H7.5V13.8535ZM7.5 7.85355V5.85355H21.5V7.85355H7.5Z"
            fill="#171B25"
        />
        <path
            d="M3.5 11.5C2.67 11.5 2 12.18 2 13C2 13.82 2.68 14.5 3.5 14.5C4.32 14.5 5 13.82 5 13C5 12.18 4.33 11.5 3.5 11.5Z"
            fill="#0A1730"
        />
    </svg>
</template>
