<template>
    <div
        aria-live="assertive"
        class="fixed z-[10000] inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <transition
                v-for="(toast, index) in toasts"
                :key="index"
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <GgmsToast :toast="toast" @clickClose="closeToast(index)"></GgmsToast>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import GgmsToast from "@/components/GgmsToast.vue"
import { ToastService } from "@/shared/services/toast"

const toastService = new ToastService()

const toasts = computed(() => {
    return toastService.getToasts()
})

function closeToast(index: number) {
    toastService.removeToast(index)
}
</script>
