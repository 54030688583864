import { Client } from "./client"
import Crud from "./crud"
import { Invoice } from "../models/invoice"

const client = new Client()

export class InvoiceService extends Crud<Invoice> {
    constructor() {
        super("crm/invoice")
    }

    updateInvoice(id: string, invoice: Invoice): Promise<{ invoice: Invoice }> {
        return client.patch(`${this.endpoint}/${id}`, invoice)
    }

    getInvoices(): Promise<Invoice[]> {
        return client.get(this.endpoint)
    }

    autocomplete(search?: string, currentId?: string): Promise<Invoice[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
                currentId,
            },
        })
    }
}
