<template>
    <p class="pb-6 text-base leading-6 font-medium text-black">Based on percentage split</p>
    <div v-for="(branch, index) in workflowStore.branches" :key="branch.label" class="flex items-center w-full gap-x-2">
        <div class="relative w-9/12 mb-5">
            <GgmsInput v-model.number="branch.value" :label="branch.label" type="number" />
            <span class="absolute inset-y-0 right-0 flex items-center pt-6 pr-2 text-gray-500">%</span>
        </div>
        <TrashIcon
            v-if="workflowStore.branches.length > 1"
            class="w-5 h-5 cursor-pointer"
            @click="removeBranch(index)"
        ></TrashIcon>
    </div>

    <GgmsButton
        styleType="simple"
        :icon-start="PlusIcon"
        class="w-fit"
        :classes="['py-0 px-0 pr-2 text-primary-color-600']"
        :icon-classes="['fill-primary-color-600']"
        @click="addBranch"
        >Add Branch</GgmsButton
    >
</template>
<script setup lang="ts">
import { onMounted } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import { PlusIcon, TrashIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"

const workflowStore = useWorkflowStore()
function addBranch() {
    workflowStore.branches.push({ value: 0, label: `Branch ${workflowStore.branches.length + 1}`, type: "percentage" })
}

function removeBranch(index: number) {
    workflowStore.branches.splice(index, 1)
    workflowStore.branches = workflowStore.branches.map((branch, index) => ({
        ...branch,
        label: `Branch ${index + 1}`,
    }))
}

onMounted(() => {
    workflowStore.areButtonDisabled = false
})
</script>
