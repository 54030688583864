import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Attribute } from "../models/attributes"
import { Property } from "../models/property"

const client = new Client()

export class AttributesService extends Crud<Attribute> {
    constructor() {
        super("crm/attributes")
    }

    createAttribute(body: Property, type: string): Promise<void> {
        return client.post(`${this.endpoint}/${type}`, body)
    }
}
