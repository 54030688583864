<template>
    <GgmsInput
        input-class="bg-gray-100 border-0"
        v-model="searchTerm"
        :small="true"
        :iconEnd="SearchIcon"
        placeholder="Search"
    ></GgmsInput>
    <div v-for="category in eventsCategories" :key="category">
        <div v-show="filteredEvents.filter((ev) => ev.type === category).length" class="flex flex-col mt-4">
            <div
                class="w-full bg-primary-color-50 text-primary-color-800 text-xs leading-4 font-semibold uppercase py-1 px-2 mb-2 rounded-md"
            >
                {{ category }}
            </div>
            <div
                v-for="event in filteredEvents.filter((ev) => ev.type === category)"
                :key="event"
                class="text-gray-900 text-base leading-6 font-medium p-2.5 cursor-pointer"
                @click="chooseEvent(event.title)"
            >
                {{ convertTriggerEvent(event.title) }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import { SearchIcon } from "@heroicons/vue/outline"
import GgmsInput from "@/components/GgmsInput.vue"
import TriggerNode from "@/components/workflows/TriggerNode.vue"
import MembershipChangedTriggerSidebar from "@/components/workflows/sidebar/trigger/MembershipChangedTriggerSidebar.vue"
import PropertyValueChangeTriggerSidebar from "@/components/workflows/sidebar/trigger/PropertyValueChangeTriggerSidebar.vue"
import SubmissionAndViewSidebar from "@/components/workflows/sidebar/trigger/SubmissionAndViewSidebar.vue"
import EnrollAndUnenrollSidebar from "@/components/workflows/sidebar/trigger/EnrollAndUnenrollSidebar.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { convertTriggerEvent } from "@/shared/utils/helpers"
import { ContactActivityTriggerNodeType, TriggerNodeType } from "@/shared/models/workflow"

const workflowStore = useWorkflowStore()

const searchTerm = ref("")
const eventsCategories = ["call", "lead", "form", "list", "workflow"]

const events = [
    { title: ContactActivityTriggerNodeType.callStarted, type: "call" },
    { title: ContactActivityTriggerNodeType.callEnded, type: "call" },
    { title: ContactActivityTriggerNodeType.propertyValueChanged, type: "lead" },
    { title: ContactActivityTriggerNodeType.leadCreated, type: "lead" },
    { title: ContactActivityTriggerNodeType.submission, type: "form" },
    { title: ContactActivityTriggerNodeType.view, type: "form" },
    { title: ContactActivityTriggerNodeType.membershipChanged, type: "list" },
    { title: ContactActivityTriggerNodeType.enrolledInWorkflow, type: "workflow" },
    { title: ContactActivityTriggerNodeType.unenrollFromWorkflow, type: "workflow" },
]

const filteredEvents = computed(() =>
    events.filter((event) => event.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

interface Props {
    modelValue: boolean
}
defineProps<Props>()

const emit = defineEmits(["update:modelValue", "changeAction"])

function chooseEvent(event: string) {
    workflowStore.modelValue = event
    switch (event) {
        case ContactActivityTriggerNodeType.membershipChanged:
            emit("changeAction", { componentName: MembershipChangedTriggerSidebar.__name })
            break
        case ContactActivityTriggerNodeType.propertyValueChanged:
            emit("changeAction", { componentName: PropertyValueChangeTriggerSidebar.__name })
            break
        case ContactActivityTriggerNodeType.submission:
            workflowStore.isSubmission = true
            emit("changeAction", { componentName: SubmissionAndViewSidebar.__name })
            break
        case ContactActivityTriggerNodeType.view:
            workflowStore.isSubmission = false
            emit("changeAction", { componentName: SubmissionAndViewSidebar.__name })
            break
        case ContactActivityTriggerNodeType.enrolledInWorkflow:
            workflowStore.isEnrollWorkflow = true
            emit("changeAction", { componentName: EnrollAndUnenrollSidebar.__name })
            break
        case ContactActivityTriggerNodeType.unenrollFromWorkflow:
            workflowStore.isEnrollWorkflow = false
            emit("changeAction", { componentName: EnrollAndUnenrollSidebar.__name })
            break
        default:
            emit("changeAction", { componentName: TriggerNode.__name, node: TriggerNodeType.contactActivity })
            break
    }
    workflowStore.setHeaderText()
}
</script>
