<template>
    <NodeToolbar
        class="flex gap-x-2 nodrag nopan"
        :is-visible="localNoteVisible"
        :position="Position.Right"
        align="start"
    >
        <div v-if="status === 'emptyClosed' || status === 'fullClosed'">
            <div v-if="value" class="bg-gray-200 p-2 rounded-lg cursor-pointer" @click="localStatus = 'fullOpen'">
                <SolidAnnotationIcon class="h-5 w-5"></SolidAnnotationIcon>
            </div>
            <div v-else class="bg-gray-200 p-2 rounded-lg cursor-pointer" @click="openEdit">
                <AnnotationIcon class="h-5 w-5"></AnnotationIcon>
            </div>
        </div>

        <div v-if="status === 'editOpen'" class="flex flex-col rounded-2xl p-2 bg-gray-200">
            <div class="flex flex-col w-full items-end">
                <XIcon class="w-5 h-5 text-gray-500 cursor-pointer" @click="closeToolbar"></XIcon>
                <div class="border-2 rounded-md border-primary-color-500">
                    <GgmsTextarea
                        v-model="value"
                        :rows="1"
                        borderless
                        rounded-top
                        :rounded-bottom="!showButtons"
                        :rounded="false"
                        placeholder="Write a note..."
                        class="w-80"
                        @input="onInput"
                    ></GgmsTextarea>
                    <div
                        v-if="showButtons"
                        class="flex justify-end w-full bg-white rounded-b-lg p-2 border-t border-t-gray-200 gap-x-1"
                    >
                        <GgmsButton style-type="secondary" @click="cancel">Cancel</GgmsButton>
                        <GgmsButton @click="save">Done</GgmsButton>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="status === 'fullOpen'" class="flex flex-col rounded-2xl p-2 bg-gray-200">
            <div class="flex flex-col w-full items-end">
                <XIcon class="w-5 h-5 text-gray-500 cursor-pointer" @click="closeToolbar"></XIcon>
                <div class="flex flex-col gap-y-3 bg-white w-80 rounded-lg px-3 py-2 leading-5 font-normal">
                    <p class="text-sm text-gray-800" @click="editNote">{{ value }}</p>
                    <div class="flex justify-between">
                        <p class="text-xs text-gray-400">Last edited {{ savingDate }}</p>
                        <TrashIcon class="w-5 h-5 text-gray-500 cursor-pointer" @click="deleteNote"></TrashIcon>
                    </div>
                </div>
            </div>
        </div>
    </NodeToolbar>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsTextarea from "@/components/GgmsTextarea.vue"
import { Position } from "@vue-flow/core"
import { NodeToolbar } from "@vue-flow/node-toolbar"
import { AnnotationIcon, XIcon, TrashIcon } from "@heroicons/vue/outline"
import { AnnotationIcon as SolidAnnotationIcon } from "@heroicons/vue/solid"
import { formatTaskDate } from "@/shared/utils/helpers"
interface Props {
    note?: { text?: string; lastEdited?: string }
    isNoteVisible?: boolean
    status: string
}

const emits = defineEmits(["update:note", "update:status", "update:noteVisible"])

const props = defineProps<Props>()

const value = ref(props.note?.text)
const initialValue = ref(props.note?.text)

const showButtons = ref(false)

const savingDate = ref(props.note?.lastEdited)

const localNoteVisible = computed({
    get() {
        return props.isNoteVisible
    },
    set(value) {
        emits("update:noteVisible", value)
    },
})

const localStatus = computed({
    get() {
        return props.status
    },
    set(value) {
        emits("update:status", value)
    },
})
function onInput() {
    showButtons.value = true
}

function editNote() {
    localStatus.value = "editOpen"
    showButtons.value = true
}

function cancel() {
    value.value = initialValue.value
    if (value.value.length) {
        localStatus.value = "fullClosed"
    } else {
        localStatus.value = "emptyClosed"
        localNoteVisible.value = false
    }
    showButtons.value = false
}
function save() {
    savingDate.value = formatTaskDate(new Date())
    showButtons.value = false
    if (!value.value?.length) {
        localStatus.value = "emptyClosed"
        localNoteVisible.value = false
    } else {
        localStatus.value = "fullClosed"
    }
    initialValue.value = value.value
    emits("update:note", { text: value.value, lastEdited: savingDate.value })
}

function closeToolbar() {
    if (localStatus.value === "fullOpen") {
        localStatus.value = "fullClosed"
        return
    }
    if (localStatus.value === "editOpen" && initialValue.value?.length) {
        cancel()
        localStatus.value = "fullClosed"
        return
    }
    localNoteVisible.value = false
    if (localStatus.value === "editOpen" && value.value?.length) {
        cancel()
        localStatus.value = "fullClosed"
        return
    }
    if (localStatus.value === "editOpen" && !value.value?.length) {
        localStatus.value = "emptyClosed"
        return
    }
}

function deleteNote() {
    value.value = ""
    initialValue.value = ""
    save()
}

function openEdit() {
    localStatus.value = "editOpen"
    initialValue.value = value.value
}
</script>
