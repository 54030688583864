import { BaseIntegration, Integration, PayloadIntegration } from "../models/integration"
import { Client } from "./client"
import Crud from "./crud"

const client = new Client()

export class IntegrationService extends Crud<Integration> {
    constructor() {
        super("/crm/integrations")
    }

    getIntegrations() {
        return client.get(this.endpoint)
    }

    canEnableIntegration(integration: Omit<PayloadIntegration, "_id">) {
        if (integration.integrationType === "inboxEmail") {
            return client.get(
                `${this.endpoint}/enable/${integration.providedIntegrationId}?redirectUrl=${integration.redirectUrl}`
            )
        }

        return client.get(`${this.endpoint}/enable/${integration.providedIntegrationId}`)
    }

    enableIntegration(integration: Omit<PayloadIntegration, "_id">) {
        return client.post(this.endpoint, integration)
    }

    disableIntegration(integrationId: string): Promise<{ integration: Integration }> {
        return client.delete(`${this.endpoint}/${integrationId}`)
    }

    getIntegration(integrationId?: string, providedIntegrationId?: string) {
        const id = providedIntegrationId || integrationId
        return client.get(`${this.endpoint}/${id}`)
    }
}
