import Crud from "@/shared/services/crud"
import { AuthUser, Social } from "@/shared/models/auth-user"
import { Phone } from "@/shared/models/phone"
import { Client } from "@/shared/services/client"
import { Invite } from "@/shared/models/invite"

const client = new Client()

export class AuthService extends Crud<AuthUser> {
    constructor() {
        super("crm/auth")
    }

    login(email: string, password: string, tokenRedirect?: boolean, rememberMe?: boolean): Promise<AuthUser> {
        return client.post("/crm/auth/login", {
            email,
            password,
            tokenRedirect,
            rememberMe,
        })
    }

    logout() {
        return client.post("/crm/auth/logout")
    }

    forgot(email: string): Promise<{ emailSent: boolean }> {
        return client.post("/crm/auth/forgot-password", {
            email,
        })
    }

    reset(token: string, newPassword: string): Promise<AuthUser> {
        return client.post("/crm/auth/reset-password", {
            token,
            newPassword,
        })
    }

    register(
        email: string,
        password: string,
        agencyName: string,
        agencyPhoneNumber: Phone,
        firstName: string,
        lastName: string,
        agencyDomain: string,
        timezone?: string,
        social?: Social
    ): Promise<AuthUser> {
        return client.post("/crm/auth/register-admin", {
            email,
            password,
            firstName,
            lastName,
            agency: {
                agencyName,
                agencyPhoneNumber,
                agencyDomains: [{ url: agencyDomain }],
                timezone,
            },
            social,
        })
    }

    registerAgent(
        email: string,
        password: string,
        phoneNumber: Phone,
        firstName: string,
        lastName: string,
        token?: string,
        social?: Social
    ): Promise<AuthUser> {
        return client.post("/crm/auth/register-agent", {
            email,
            password,
            phoneNumber,
            firstName,
            lastName,
            token,
            social,
        })
    }

    confirmEmail(token: string): Promise<AuthUser> {
        return client.post("/crm/auth/email-confirmation", {
            token,
        })
    }

    getInvite(token: string): Promise<Invite> {
        return client.get(`/crm/auth/invite/${token}`)
    }

    googleLogin(user: "admin" | "agent") {
        return client.get(`/crm/auth/login/google-${user}`)
    }

    acceptInvite(token: string): Promise<AuthUser> {
        return client.post(`${this.endpoint}/accept-invite/`, { token })
    }

    autoLogin(token: string): Promise<AuthUser> {
        return client.post(`${this.endpoint}/auto-login`, { token })
    }
}
