import { TableQueryParams } from "@/shared/models/table-query-params"
import { PagedResponse } from "@/shared/models/paged-response"
import { Client } from "@/shared/services/client"
import { Filter } from "@/shared/models/filter"

const client = new Client()

export default class Crud<T> {
    constructor(protected endpoint: string) {}

    getAllOld(queryParams: TableQueryParams): Promise<PagedResponse<T>> {
        queryParams.filters = queryParams?.filters?.length
            ? JSON.stringify(
                  queryParams?.filters?.map((filter: Filter) => {
                      return {
                          field: filter.field,
                          rule: filter.rule,
                          value: filter.value,
                      }
                  })
              )
            : undefined

        return client.get(this.endpoint, { params: queryParams })
    }

    getAll(queryParams: TableQueryParams): Promise<PagedResponse<T>> {
        return client.get(this.endpoint, { params: queryParams })
    }

    getAllPost(queryParams: TableQueryParams): Promise<PagedResponse<T>> {
        return client.post(this.endpoint, queryParams)
    }

    getOne(id: string) {
        return client.get(`${this.endpoint}/${id}`)
    }

    deleteOne(id: string) {
        return client.delete(`${this.endpoint}/${id}`)
    }

    updateOne(id: string, data: Partial<T>): Promise<T> {
        return client.put(`${this.endpoint}/${id}`, data)
    }

    addOne(data: Partial<T>): Promise<T> {
        return client.post(this.endpoint, data)
    }
}
