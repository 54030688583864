import Crud from "./crud"
import { BulkImportData, CreateJobResponse, Job } from "@/shared/models/jobs"
import { Client } from "@/shared/services/client"

const client = new Client()

export class JobService extends Crud<Job> {
    constructor() {
        super("crm/jobs")
    }

    bulkDelete(ids: string[]): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/bulk/delete`, {
            ids,
        })
    }

    bulkImport(data: BulkImportData) {
        const files = new FormData()
        if (!data) {
            return
        }
        if (Array.isArray(data.file)) {
            data.file.forEach((file) => {
                files.append("files[]", file)
            })
        } else {
            files.append("file", data.file)
        }
        files.append("type", data.type)

        return client.post(`${this.endpoint}/bulk/import`, files)
    }

    addJob(data: Partial<Job>): Promise<CreateJobResponse> {
        return client.post(this.endpoint, data)
    }

    updateJob(id: string, data: Partial<Job>): Promise<{ job: Job }> {
        return client.patch(`${this.endpoint}/${id}`, data)
    }

    searchJobs(search: string, hasPhone: undefined | boolean, limit: undefined | number): Promise<Job[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
                hasPhone,
                limit,
            },
        })
    }
}
