<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black">Attach custom note</p>
        <p class="text-sm leading-5 font-normal text-gray-500 pb-4">Attach a custom note to the enrolled leads.</p>
        <div class="flex items-center gap-x-1 pb-6">
            <GgmsCheckBox v-model="workflowStore.modelValue.event" label="Pin note"></GgmsCheckBox>
            <InformationCircleIcon class="w-4 h-4 cursor-pointer"></InformationCircleIcon>
        </div>
        <GgmsRichTextEditor
            v-model="workflowStore.modelValue.value"
            placeholder="Write your note...."
        ></GgmsRichTextEditor>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsRichTextEditor from "@/components/GgmsRichTextEditor.vue"
import { InformationCircleIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { stripHtml } from "@/shared/utils/helpers"

const workflowStore = useWorkflowStore()

watch(
    () => [workflowStore.modelValue.event, workflowStore.modelValue.value],
    () => {
        workflowStore.areButtonDisabled = !stripHtml(workflowStore.modelValue as string)
    }
)
</script>
