import Crud from "./crud"
import { BulkImportData, CreateLeadResponse, Lead } from "@/shared/models/lead"
import { Client } from "@/shared/services/client"
import { Tag } from "@/shared/models/tag"
import { EditableAddressPayload, EditableEmail, EditablePhonePayload } from "@/shared/models/contact-information"
import { ACL } from "@/shared/models/role"

const client = new Client()

export class LeadService extends Crud<Lead> {
    constructor() {
        super("crm/leads")
    }

    bulkDelete(ids: string[]): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/bulk/delete`, {
            ids,
        })
    }

    bulkImport(data: BulkImportData) {
        const files = new FormData()
        if (!data) {
            return
        }
        if (Array.isArray(data.file)) {
            data.file.forEach((file) => {
                files.append("files[]", file)
            })
        } else {
            files.append("file", data.file)
        }
        files.append("type", data.type)

        return client.post(`${this.endpoint}/bulk/import`, files)
    }

    getAssignedAgentsACLs(ids: string) {
        return client.get(`${this.endpoint}/permissions`, {
            params: {
                ids,
            },
        })
    }

    addLead(data: Partial<Lead>): Promise<CreateLeadResponse> {
        return client.post(this.endpoint, data)
    }

    updateLead(id: string, data: Partial<Lead>): Promise<{ lead: Lead }> {
        return client.patch(`${this.endpoint}/${id}`, data)
    }

    bulkUpdateTagOnLead(leadIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/tags`, {
            ids: leadIds,
            all,
            updates: {
                toAdd,
                toRemove,
            },
        })
    }

    bulkUpdatePermissionsOnLead(
        leadIds: string[],
        toAdd: ACL[],
        toRemove: string[],
        listsToAdd: ACL[],
        listsToRemove: string[],
        all: boolean
    ) {
        return client.post(`${this.endpoint}/bulk/update/acl`, {
            ids: leadIds,
            updates: {
                toAdd,
                toRemove,
                listsToAdd,
                listsToRemove,
                all,
            },
        })
    }

    searchLeads(search: string, hasPhone: undefined | boolean, limit: undefined | number): Promise<Lead[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
                hasPhone,
                limit,
            },
        })
    }

    updateLeadsEmail(leadId: string, emails: EditableEmail[]) {
        return client.put(`${this.endpoint}/${leadId}/emails`, { emails })
    }

    updateLeadsPhone(leadId: string, phones: EditablePhonePayload[]) {
        return client.put(`${this.endpoint}/${leadId}/phones`, { phones })
    }

    updateLeadsAddress(leadId: string, addresses: EditableAddressPayload[]) {
        return client.put(`${this.endpoint}/${leadId}/addresses`, { addresses })
    }

    updateStage(leadId: string, stage: Tag) {
        const payload = !Object.keys(stage).length ? {} : { stage }
        return client.put(`${this.endpoint}/${leadId}/stage`, payload)
    }

    shareLead(leadId: string, agentsEmails: string[], permissionType: string) {
        return client.post(`${this.endpoint}/share/${leadId}`, { agentsEmails, permissionType })
    }

    unsubscribeLead(token: string, leadId: string) {
        return client.get(`${this.endpoint}/unsubscribe`, { params: { token, leadId } })
    }
}
