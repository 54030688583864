<template>
    <div class="relative w-6 h-6">
        <EmojiHappyIcon
            class="w-6 h-6 mr-4 cursor-pointer text-gray-400 hover:text-gray-600"
            @click="toggleEmojiPicker"
        />
        <div v-show="isShowEmojiPicker" class="absolute z-50 -right-[245px]" :class="xIconAlign">
            <XIcon class="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-500" @click="hideEmojiPicker"></XIcon>
        </div>
        <EmojiPicker
            v-show="isShowEmojiPicker"
            :native="true"
            class="absolute z-40"
            :class="[pickerClasses, emojiPickerAlign]"
            @select="onSelectEmoji"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import EmojiPicker from "vue3-emoji-picker"
import { EmojiHappyIcon, XIcon } from "@heroicons/vue/outline"
import { Emoji } from "@/shared/models/emoji"
import "vue3-emoji-picker/css"

interface Props {
    pickerClasses?: string
    isAlignedTop?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(["sendEmoji"])

const isShowEmojiPicker = ref(false)
const emojiPickerAlign = computed(() => (props.isAlignedTop ? "bottom-7" : "top-7"))
const xIconAlign = computed(() => (props.isAlignedTop ? "bottom-[315px]" : "top-9"))

function onSelectEmoji(emoji: Emoji) {
    emit("sendEmoji", emoji.i)
}

function toggleEmojiPicker() {
    isShowEmojiPicker.value = !isShowEmojiPicker.value
}

function hideEmojiPicker() {
    isShowEmojiPicker.value = false
}
</script>

<style scoped:deep>
.v3-emoji-picker .v3-header {
    padding: 40px 15px 13px !important;
}
</style>
