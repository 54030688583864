<template>
    <svg width="24" height="24" viewBox="0 0 24 24" :class="props.class" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 8.4V4C14 2.34315 12.6569 1 11 1C9.82238 1 8.80325 1.67852 8.3122 2.66593M11 18V21M11 18C7.13401 18 4 14.866 4 11V9M11 18C14.866 18 18 14.866 18 11V9M7 21H15M1 1L21 21M11 14C9.34315 14 8 12.6569 8 11V8L13.1226 13.12C12.5796 13.6637 11.8291 14 11 14Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts" setup>
import { withDefaults } from "vue"

interface Props {
    class?: string
}

const props = withDefaults(defineProps<Props>(), {
    class: "",
})
</script>
