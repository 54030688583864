import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Template, TemplatePayload } from "../models/template"

const client = new Client()

export class TemplateService extends Crud<Template> {
    constructor() {
        super("/crm/messaging/templates")
    }

    async createTemplate(body: TemplatePayload): Promise<{ templates: Template[] }> {
        const response = await client.post(this.endpoint, body)
        if (response?.template) {
            return { templates: [response.template] }
        }
        return response
    }

    updateTemplate(templateId: string, body: TemplatePayload): Promise<{ template: Template }> {
        return client.put(`${this.endpoint}/${templateId}`, body)
    }

    deleteTemplate(templateId: string): Promise<{ category: Template }> {
        return client.delete(`${this.endpoint}/${templateId}`)
    }
}
