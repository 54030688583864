import { TimelineItem } from "@/shared/models/timelineItem"
import { TimelineService } from "@/shared/services/timeline"
import { defineStore } from "pinia"
import { isScheduledMessage, isScheduledEmail } from "@/shared/utils/helpers"
import { ScheduledMessageItem } from "@/shared/models/message-item"
import { ScheduledEmailItem } from "@/shared/models/email"

export const useTimelineStore = defineStore("timeline", {
    state: () => ({
        timelineService: new TimelineService(),
        timelineItems: [] as TimelineItem[],
        totalTimelineItems: 0,
        start: 0 as number | undefined,
        length: 20,
        type: undefined as string | undefined,
        isTimelineLoading: false,
        pinnedTimelineItem: {} as TimelineItem,
        selectedTimelineFilterFromStore: "",
        selectedTimelineFilterTypeIndexFromStore: null as number | null,
    }),
    actions: {
        async getTimeline(leadId: string, afterId: string | undefined = undefined) {
            try {
                this.isTimelineLoading = true
                const response = await this.timelineService.getTimeline(
                    leadId,
                    afterId,
                    this.length,
                    this.start,
                    this.type
                )
                if (!response) return
                this.timelineItems = afterId ? this.timelineItems.concat(response.data) : response.data
                this.totalTimelineItems = response.total

                return this.totalTimelineItems
            } finally {
                this.isTimelineLoading = false
            }
        },

        async pinTimelineItem(id: string, isPinned: boolean) {
            try {
                this.isTimelineLoading = true
                const { timeLineItem: timelineItem } = await this.timelineService.pinTimelineItem(id, isPinned)
                this.pinnedTimelineItem = isPinned ? timelineItem : ({} as TimelineItem)

                const index = this.timelineItems.findIndex((item) => item._id === id)
                isPinned ? (this.timelineItems[index].pinned = true) : delete this.timelineItems[index].pinned
            } finally {
                this.isTimelineLoading = false
            }
        },

        getTimelineItems() {
            return this.timelineItems.filter((timelineItem) => {
                if (
                    !isScheduledMessage(timelineItem.item as Partial<ScheduledMessageItem>) &&
                    !isScheduledEmail(timelineItem.item as Partial<ScheduledEmailItem>) &&
                    !timelineItem?.pinned
                )
                    return timelineItem
            })
        },

        getPinnedTimelineItem() {
            return this.timelineItems.find((timelineItem) => timelineItem.pinned) as TimelineItem
        },

        getScheduledMessageItems() {
            return this.timelineItems.filter((timelineItem) => {
                if (isScheduledMessage(timelineItem.item as Partial<ScheduledMessageItem>)) return timelineItem
            })
        },

        getScheduledEmailItems() {
            return this.timelineItems.filter((timelineItem: TimelineItem) => {
                if (isScheduledEmail(timelineItem.item as Partial<ScheduledEmailItem>)) return timelineItem
            })
        },
    },
})
