<template>
    <div class="flex flex-col gap-4">
        <div class="flex items-center gap-4">
            <ArrowLeftIcon
                v-if="canGoBack"
                class="h-6 w-6 text-primary-color-700 cursor-pointer shrink-0"
                @click.stop="cancelFilter"
            />
            <GgmsInput v-model="searchQuery" :iconStart="SearchIcon" placeholder="Search" class="w-full" />
        </div>
        <div class="border border-gray-200 rounded-lg shadow-sm bg-white py-2">
            <div v-for="prototype in prototypes" :key="prototype.typeName">
                <div class="flex flex-col gap-1 px-2">
                    <div
                        v-if="getFilteredAttributes(prototype.attributes).length === 0 && searchQuery"
                        class="p-2.5 text-gray-900"
                    >
                        No results
                    </div>
                    <div
                        v-else
                        v-for="attribute in getFilteredAttributes(prototype.attributes)"
                        :key="attribute._id"
                        class="p-2.5 text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg"
                        @click.stop="selectAttribute(attribute)"
                    >
                        {{ attribute.displayName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { SearchIcon, ArrowLeftIcon } from "@heroicons/vue/outline"
import { Attribute } from "@/shared/models/attributes"
import { useGridCollectionStore } from "@/stores/grid-collection"

const gridCollectionStore = useGridCollectionStore()

const emit = defineEmits(["onAttributeSelect", "cancel"])

const searchQuery = ref("")
const prototypes = computed(() => gridCollectionStore.prototypes)
const canGoBack = computed(() => gridCollectionStore.collectionTypeHistory.length > 1)

function getFilteredAttributes(attributes: Attribute[]) {
    return attributes.filter((attribute) =>
        attribute.displayName.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
}

function selectAttribute(attribute: Attribute) {
    emit("onAttributeSelect", attribute)
}

function cancelFilter() {
    emit("cancel")
}
</script>
