<template>
    <div class="flex flex-col gap-y-3">
        <p class="text-lg leading-7 font-medium">Choose how to trigger this workflow:</p>
        <TriggerCard
            title="Trigger on contact activity"
            example="When a website visitor fills out a form on the contact page of your website"
            @click="changeAction(ContactActivityTriggerSidebar)"
        />

        <TriggerCard
            title="Schedule trigger"
            example="enroll all contacts at a certain time"
            @click="changeAction(ScheduleTriggerSidebar)"
        />
    </div>
</template>
<script lang="ts" setup>
import TriggerCard from "@/components/workflows/TriggerCard.vue"
import ContactActivityTriggerSidebar from "@/components/workflows/sidebar/trigger/ContactActivityTriggerSidebar.vue"
import { Component } from "vue"
import ScheduleTriggerSidebar from "@/components/workflows/sidebar/trigger/schedule/ScheduleTriggerSidebar.vue"

interface Props {
    modelValue: boolean
}
defineProps<Props>()

const emit = defineEmits(["update:modelValue", "changeAction"])

function changeAction(sidebarComponent: Component) {
    emit("changeAction", {
        sidebarComponent,
    })
}
</script>
